import React from "react";
import { styled, useTheme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import IconButton from "@material-ui/core/IconButton";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItem from "@mui/material/ListItem";
import { sideNavigation } from "../constants/sideNav";
import Icon from "../shared-components/Icon";
import { NavLink } from "react-router-dom";
const drawerWidth = 240;
const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: "flex-end",
}));

const useStyles = makeStyles({
  navList: {
    // border: '0 !important',
    // top: '0 !important',
    // pointerEvents:'auto !important',
    
    "& li": {
      width: "100%",
      marginBottom: 8,
      fontSize: 14,

      "& a": {
        width: "100%",
        display: "flex",
        alignItems: "center",
        padding: "8px 20px",
        color: "rgba(26,51,83,.85)",
        textDecoration: "none",
        borderRight: "3px solid transparent",

        "& .MuiSvgIcon-root": {
          color: "inherit",
          marginRight: 10,
        },

        "&:hover": {
          color: "#3e82f7",
        },

        "&.active": {
          background: "rgba(62,130,247,.1)",
          color: "#3e82f7",
          borderRightColor: "#3e82f7",
        },
      },
    },
  },
});

const SideNav = ({ open, handleDrawerClose }) => {
  const theme = useTheme();
  const classes = useStyles();
  return (
    <Drawer
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        "& .MuiDrawer-paper": {
          width: drawerWidth,
          boxSizing: "border-box",
        },
      }}
      variant="persistent"
      anchor="left"
      open={open}
    >
      <DrawerHeader>
        <IconButton onClick={handleDrawerClose}>
          {theme.direction === "ltr" ? (
            <ChevronLeftIcon />
          ) : (
            <ChevronRightIcon />
          )}
        </IconButton>
      </DrawerHeader>
      <Divider />
      <List className={classes.navList}>
        {sideNavigation.map(({ id, name, path, icon, isVisible }) => (
          <React.Fragment key={id}>
            {isVisible && (
              <ListItem disablePadding>
                <NavLink to={path}>
                  <Icon type={icon} /> <span>{name}</span>
                </NavLink>
              </ListItem>
            )}
          </React.Fragment>
        ))}
      </List>
    </Drawer>
  );
};

export default SideNav;
