import {
  EVENT_ROLE_TYPE_ID_SUCCESS,
  EVENT_ROLE_TYPE_ID_RESET,
} from "../action/EventTypeIdAction";

const initState = {
  details: [],
};

const eventRoleTypeId = (state = initState, action) => {
  switch (action.type) {
    case EVENT_ROLE_TYPE_ID_SUCCESS:
      let data = state.details;
      const index =
        data &&
        data.findIndex(
          (item) =>
            item.type === action.data.type &&
            item.eventTypeId === action.data.eventTypeId
        );
      if (index !== -1) {
        data && data.splice(index, 1);
      }
      data && data.push(action.data);
      return {
        ...state,
        details: data,
      };

    case EVENT_ROLE_TYPE_ID_RESET:
      return {
        ...state,
        details: [],
      };

    default:
      return state;
  }
};

export default eventRoleTypeId;
