export const DATA_TABLE_META_DATA = "DATA_TABLE_META_DATA";

export const fetchDataTableMetaData = (page,rowPerPageValue, pageSizeValue,year,yearDate) => {
  const resp = {};
  resp["page"] = page;
  resp["rowPerPageValue"] = rowPerPageValue;
  resp["pageSizeValue"] = pageSizeValue;
  resp["year"]=year;
  resp["yearDate"]=yearDate;
  return {
    type: DATA_TABLE_META_DATA,
    data: resp,
  };
};
