import {
  ADD_EVENT_REQUEST,
  ADD_EVENT_SUCCESS,
  ADD_EVENT_FAILURE,
  ADD_EVENT_RESET,
} from "../action/AddEventAction";

const initState = {
  loading: false,
  isError: false,
  data: "",
  status: "",
};

const addEventReducer = (state = initState, action) => {
  switch (action.type) {
    case ADD_EVENT_REQUEST:
      return {
        ...state,
        loading: true,
        isError: false,
        data: "",
        status: "",
      };
    case ADD_EVENT_SUCCESS:
      return {
        ...state,
        loading: false,
        isError: false,
        data: action.data,
        status: action.status,
      };
    case ADD_EVENT_FAILURE:
      return {
        ...state,
        loading: false,
        data: "",
        isError: true,
        status: action.status,
      };
    case ADD_EVENT_RESET:
      return {
        ...state,
        loading: false,
        data: "",
        isError: false,
        status: "",
      };

    default:
      return state;
  }
};

export default addEventReducer;
