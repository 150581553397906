import {
  OVERALL_BOARD_MEMBER_ENGAGEMENT_SUCCESS,
  OVERALL_BOARD_MEMBER_ENGAGEMENT_REQUSET,
  OVERALL_BOARD_MEMBER_ENGAGEMENT_FAILURE,
  OVERALL_BOARD_MEMBER_ENGAGEMENT_RESET,
} from "../action/OverallBoardMemberEngagement";

const initState = {
  loading: false,
  isError: false,
  data: "",
};

const overallBoardMemberengagement = (state = initState, action) => {
  switch (action.type) {
    case OVERALL_BOARD_MEMBER_ENGAGEMENT_REQUSET:
      return {
        ...state,
        loading: true,
        isError: false,
        data: "",
      };
    case OVERALL_BOARD_MEMBER_ENGAGEMENT_SUCCESS:
      return {
        ...state,
        loading: false,
        isError: false,
        data: action.data,
      };
    case OVERALL_BOARD_MEMBER_ENGAGEMENT_FAILURE:
      return {
        ...state,
        loading: false,
        data: "",
        isError: true,
      };
    case OVERALL_BOARD_MEMBER_ENGAGEMENT_RESET:
      return {
        ...state,
        loading: false,
        data: "",
        isError: false,
      };

    default:
      return state;
  }
};

export default overallBoardMemberengagement;
