import { GET_EVENT_SUCCESS, GET_EVENT_RESET } from "../action/GetEventAction";

const initState = {
  paginationData: [],
};

const eventListReducer = (state = initState, action) => {
  switch (action.type) {
    case GET_EVENT_SUCCESS:
      let data = state.paginationData;
      const index =
        data &&
        data.findIndex(
          (item) =>
            item.pageSize === action.data.pageSize &&
            item.pageNumber === action.data.pageNumber &&
            item.direction === action.data.direction &&
            item.orderBy === action.data.orderBy &&
            item.eventYear===action.data.eventYear 
        );
      if (index !== -1) {
        data && data.splice(index, 1);
      }
      data && data.push(action.data);
      return {
        ...state,
        paginationData: data,
      };

    case GET_EVENT_RESET:
      return {
        ...state,
        paginationData: [],
      };

    default:
      return state;
  }
};

export default eventListReducer;
