import { string, object } from "yup";

const amountRegExp = /^[+-]?([0-9]+\.?[0-9]*|\.[0-9]+)$/;
// /^0*([1-9]|[1-8][0-9]|9[0-9]|[1-8][0-9]{2}|9[0-8][0-9]|99[0-9]|[1-8][0-9]{3}|9[0-8][0-9]{2}|99[0-8][0-9]|999[0-9]|[1-8][0-9]{4}|9[0-8][0-9]{3}|99[0-8][0-9]{2}|999[0-8][0-9]|9999[0-9]|[1-4][0-9]{5}|500000)$/;
const emailRegEx =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const loginSchema = ({ username, password }) => {
  return object().shape({
    username: string().required(username).trim(),
    password: string().required(password).trim(),
  });
};

export const AddContributionSchema = ({
  boardMember,
  contributionType,
  contributionDate,
  description,
  amount,
}) => {
  return object().shape({
    boardMember: string().required(boardMember).trim(),
    contributionType: string().required(contributionType).trim(),
    contributionDate: string().required(contributionDate).trim().nullable(),
    description: string().required(description).trim(),
    amount: string()
      .required(amount)
      .matches(amountRegExp, "Please enter valid amount")
      .trim(),
  });
};
export const AddEventSchema = ({ eventDate, eventTypeId, name }) => {
  return object().shape({
    eventDate: string().required(eventDate).trim().nullable(),
    eventTypeId: string().required(eventTypeId).trim(),
    name: string().required(name).trim(),
  });
};

export const AddMemberSchema = ({ firstName, lastName, email }) => {
  return object().shape({
    firstName: string().required(firstName).trim(),
    lastName: string().required(lastName).trim(),
    email: string()
      .matches(emailRegEx, "Please enter the valid email")
      .required(email)
      .trim(),
    postalCode: string()
      .nullable()
      .matches(/^\d{5}(?:[-\s]\d{4})?$/, "Zip code is invalid")
      .max(5),
  });
};
