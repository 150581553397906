import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Box from "@mui/material/Box";

export const AlertDialog = ({
  open,
  onclose,
  confirmButton,
  CancelButton,
  content,
  handleConfirm,
}) => {
  return (
    <div>
      <Dialog
        open={open}
        onClose={onclose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Confirmation"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {content}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Box p={2} display="flex" textAlign="right">
            <Box mr={2}>
              <Button
                variant="contained"
                color="primary"
                onClick={handleConfirm}
              >
                {confirmButton}
              </Button>
            </Box>
            <Button
              variant="outlined"
              color="primary"
              onClick={onclose}
              autoFocus
            >
              {CancelButton}
            </Button>
          </Box>
        </DialogActions>
      </Dialog>
    </div>
  );
};
