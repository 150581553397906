import http from "../axios/AxiosInterceptor";
import { resetGetEvent } from "./GetEventAction";

export const ADD_EVENT_REQUEST = "ADD_EVENT_REQUEST";
export const ADD_EVENT_SUCCESS = "ADD_EVENT_SUCCESS";
export const ADD_EVENT_FAILURE = "ADD_EVENT_FAILURE";
export const ADD_EVENT_RESET = "ADD_EVENT_RESET";
export const requestGetEventAdd = () => {
  return {
    type: ADD_EVENT_REQUEST,
  };
};

export const receiveGetEventAdd = (data, status) => {
  return {
    type: ADD_EVENT_SUCCESS,
    data,
    status,
  };
};

export const failureGetEventAdd = (status) => {
  return {
    type: ADD_EVENT_FAILURE,
    status,
  };
};

export const resetGetEventAdd = () => {
  return {
    type: ADD_EVENT_RESET,
  };
};

export const fetchGetEventAddFunction = (request) => {
  return (dispatch) => {
    dispatch(requestGetEventAdd());

    const url = process.env.REACT_APP_API_DOMAIN + `/Event?api-version=${process.env.REACT_APP_API_VERSION}`;
    http
      .post(url, request)
      .then((response) => {
        const data = response.data;
        if (response.status !== 201) {
          dispatch(failureGetEventAdd(response.status));
        } else {
          dispatch(receiveGetEventAdd(data, response.status));
          dispatch(resetGetEvent());
        }
      })
      .catch((err) => {
        dispatch(failureGetEventAdd(err.response.status));
      });
  };
};
