import React from "react";
import { Formik, Form, Field } from "formik";
import { TextField } from "formik-material-ui";
import { AddEventSchema } from "../validationSchema";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import { FormHelperText } from "@material-ui/core";
import KeyBoardDatePicker from "../../common/datePicker/KeyboardDatePicker";
import { useDispatch } from "react-redux";
import { fetchGetEventAddFunction } from "../../action/AddEventAction";
import EventTypeDropDown from "../../common/EventType";
import { getFormattedDateWithUTC, YYYY_MM_DD } from "../../utils/dateUtils";
import { fetchEditEventFunction } from "../../action/EditEventAction";
const AddEventForm = ({
  setHasPanelClose,
  viewFormData,
  onEditPanelClose,
  type,
  hasPanelOpen,
}) => {
  const dispatch = useDispatch();
  const onSubmit = (value) => {
    const data = {
      eventTypeId: value.eventTypeId,
      name: value.name,
      eventDate: getFormattedDateWithUTC(value.eventDate, YYYY_MM_DD),
    };

    if (hasPanelOpen) {
      dispatch(fetchGetEventAddFunction(data));
      setHasPanelClose();
    } else {
      dispatch(fetchEditEventFunction(data, viewFormData.id));
      onEditPanelClose();
    }
  };

  const validationMessage = {
    eventDate: "Event Date is required ",
    eventTypeId: "Event type id is required ",
    name: "Name is required ",
  };
  
  return (
    <Box>
      <Formik
        initialValues={{
          eventDate: viewFormData ? viewFormData.eventDate : null,
          eventTypeId: viewFormData ? viewFormData.eventTypeId : "",
          name: viewFormData ? viewFormData.name : "",
        }}
        onSubmit={onSubmit}
        validationSchema={AddEventSchema(validationMessage)}
      >
        {(formikProps) => {
          const { values, errors, touched, submitForm, setFieldValue } =
            formikProps;

          return (
            <Form>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <Box
                    className="datePickerOuter"
                    data-error={touched.eventDate && errors.eventDate}
                  >
                    <Field
                      id="eventDate"
                      name="eventDate"
                      value={values.eventDate}
                      label="Event Date"
                      variant="outlined"
                      fullWidth
                      component={() => (
                        <KeyBoardDatePicker
                          label="Event Date"
                          value={values.eventDate}
                          disabled={type === "VIEW" ? true : false}
                          onChange={(date) => {
                            if (date !== "Invalid Date") {
                              setFieldValue("eventDate", date);
                            }
                          }}
                        />
                      )}
                    />
                    {touched.eventDate && (
                      <FormHelperText>{errors.eventDate}</FormHelperText>
                    )}
                  </Box>
                </Grid>
                <Grid item xs={6}>
                  <Box>
                    <EventTypeDropDown
                      value={values?.eventTypeId}
                      setDropDownObject={(value) => {
                        setFieldValue("eventTypeId", value?.id);
                      }}
                      touched={touched?.eventTypeId}
                      errors={errors?.eventTypeId}
                      disabled={type === "VIEW" ? true : false}
                    />
                  </Box>
                </Grid>

                <Grid item xs={12}>
                  <Box my={1}>
                    <Field
                      id="name"
                      name="name"
                      type="text"
                      label="Name"
                      // value={values.password ? values.password : ""}
                      variant="outlined"
                      fullWidth
                      component={TextField}
                      helperText={touched.name && errors.name}
                      disabled={type === "VIEW" ? true : false}
                    />
                  </Box>
                </Grid>
                {type !== "VIEW" && (
                  <Grid item xs={12}>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={submitForm}
                    >
                      Submit
                    </Button>
                  </Grid>
                )}
              </Grid>
            </Form>
          );
        }}
      </Formik>
    </Box>
  );
};

export default AddEventForm;
