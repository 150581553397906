import http from "../axios/AxiosInterceptor";

export const BOARD_MEMBER_REQUSET = "BOARD_MEMBER_REQUSET";
export const BOARD_MEMBER_SUCCESS = "BOARD_MEMBER_SUCCESS";
export const BOARD_MEMBER_FAILURE = "BOARD_MEMBER_FAILURE";
export const BOARD_MEMBER_RESET = "BOARD_MEMBER_RESET";
export const requestBoardMembers = () => {
  return {
    type: BOARD_MEMBER_REQUSET,
  };
};

export const recieveBoardMembers = (data) => {
  return {
    type: BOARD_MEMBER_SUCCESS,
    data,
  };
};

export const failureBoardMembers = () => {
  return {
    type: BOARD_MEMBER_FAILURE,
  };
};

export const resetBoardMembers = () => {
  return {
    type: BOARD_MEMBER_RESET,
  };
};

export const fetchBoardMembers = () => {
  return (dispatch) => {
    dispatch(requestBoardMembers());
    // const url = "https://wdcboardmemberapi-dev.azurewebsites.net/boardmember";
    const url = process.env.REACT_APP_API_DOMAIN + `/BoardMember?api-version=${process.env.REACT_APP_API_VERSION}`
    http
      .get(url)
      .then((response) => {
        const data = response.data;
        if (response.status !== 200) {
          dispatch(failureBoardMembers());
        } else {
          dispatch(recieveBoardMembers(data));
        }
      })
      .catch((err) => {
        dispatch(failureBoardMembers());
      });
  };
};
