import { DATA_TABLE_META_DATA } from "../action/dataTableMetaDataAction";

const initState = {
  paginationData: [],
};

const dataTableMetaData = (state = initState, action) => {
  switch (action.type) {
    case DATA_TABLE_META_DATA:
      let data = state.paginationData;
      const index =
        data && data.findIndex((item) => item.page === action.data.page);
      if (index !== -1) {
        data && data.splice(index, 1);
      }
      data && data.push(action.data);
      return {
        ...state,
        paginationData: data,
      };

    default:
      return state;
  }
};

export default dataTableMetaData;
