import React from "react";
import { DataGrid } from "@mui/x-data-grid";
import { Box } from "@mui/material";

const DataTable = ({
  page,
  rows,
  columns,
  pageSize,
  onPageSizeChange,
  onPageChange,
  totalPage,
  loading,
  onSortModelChange,
  sortModel,
}) => {
  return (
    <Box sx={{ height: 561, width: "100%" }}>
      <DataGrid
        page={page}
        rows={rows}
        rowHeight={45}
        columns={columns}
        pageSize={pageSize}
        rowsPerPageOptions={[5, 10, 50, 100]}
        disableSelectionOnClick
        onPageSizeChange={onPageSizeChange}
        onPageChange={onPageChange}
        rowCount={totalPage}
        hideFooterSelectedRowCount={true}
        paginationMode="server"
        loading={loading}
        sortModel={sortModel}
        pagination
        onSortModelChange={(newSortModel) => onSortModelChange(newSortModel)}
      />
    </Box>
  );
};

export default DataTable;
