import moment from "moment";

export const YYYY_MM_DD = "YYYY-MM-DD";
export const MMDDYYYYWITHSLASH = "MM/DD/YYYY";

export const getStartDateOfCurrentYear = () => {
  return moment().startOf("year").format(YYYY_MM_DD);
};

export const getEndDateOfCurrentYear = () => {
  return moment().endOf("year").format(YYYY_MM_DD);
};

export const getStartDateOfCurrentMonth = () => {
  return moment().startOf("month").format(YYYY_MM_DD);
};

export const getEndDateOfCurrentMonth = () => {
  return moment().endOf("month").format(YYYY_MM_DD);
};

export const getFormattedDate = (date, format) => {
  return moment(date).format(format);
};

export const getFormattedDateWithUTC = (date, format) => {
  return moment(date).utc(true).format(format);
};

export const amountMasking = (currency) => {
  if (currency) {
    const amount = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
    }).format(currency);
    return amount;
  } else {
    return "";
  }
};

export const getStartDateOfSelectedYear = (year) => {
  return moment([year]).startOf("year").format(YYYY_MM_DD);
};

export const getEndDateOfSelectedYear = (year) => {
  return moment([year]).endOf("year").format(YYYY_MM_DD);
};
