import { Box } from "@mui/material";
import React from "react";
import { CustomDialog } from "./CustomDialogBox";

export default function SessionTimeoutDialog({
  remainingTime,
  dialogOpen,
  onRefreshSession,
  onSignOut,
  content,
}) {
  const minutesRemaining = Math.floor(remainingTime / 60);
  const secondsRemaining = remainingTime % 60;

  return (
    <CustomDialog
      open={dialogOpen}
      onClose={() => onRefreshSession()}
      handleConfirm={() => onSignOut()}
      showConfirmButton
      content={{
        dialogTitle: content?.title,
        confirmButton: content?.signOutButton,
        closeButton: content?.staySignedInButton,
        dialogContent: (
          <Box width={450} className="font400" textAlign="center">
            <span>{content?.content} </span>
            <span>
              {minutesRemaining > 0 &&
                `${minutesRemaining} ${content?.minutesText} `}
            </span>
            <span>
              {secondsRemaining < 10
                ? `0${secondsRemaining}`
                : secondsRemaining}
            </span>
            <span> {content?.secondsLeftText}</span>
          </Box>
        ),
      }}
    />
  );
}
