import http from "../axios/AxiosInterceptor";

export const EVENT_ROLE_TYPE_SUCCESS = "EVENT_ROLE_TYPE_SUCCESS";
export const EVENT_ROLE_TYPE_RESET = "EVENT_ROLE_TYPE_RESET";
export const requestEventRoleType = (type) => {
  const resp ={}
  resp["isFetching"] = true
  resp["isError"] = false
  resp["type"] = type
  return {
    type: EVENT_ROLE_TYPE_SUCCESS,
    data:resp,
  };
};

export const receiveEventRoleType = (type, data) => {
  const resp ={}
  resp["isFetching"] = false
  resp["isError"] = false
  resp["type"] = type
  resp["data"] = data
  return {
    type: EVENT_ROLE_TYPE_SUCCESS,
    data: resp,
  };
};

export const failureEventRoleType = (type) => {
  const resp ={}
  resp["isFetching"] = false
  resp["isError"] = true
  resp["type"] = type
  return {
    type: EVENT_ROLE_TYPE_SUCCESS,
    data: resp,
  };
};

export const resetEventRoleType = () => {
  return {
    type: EVENT_ROLE_TYPE_RESET,
  };
};

export const fetchEventRoleType = (type) => {
  return (dispatch) => {
    dispatch(requestEventRoleType(type));

    const url = process.env.REACT_APP_API_DOMAIN + `/Event/roletype?api-version=${process.env.REACT_APP_API_VERSION}`;
    http
      .get(url)
      .then((response) => {
        const data = response.data;
        if (response.status !== 200) {
          dispatch(failureEventRoleType(type));
        } else {
          dispatch(receiveEventRoleType( type,data));
        }
      })
      .catch((err) => {
        dispatch(failureEventRoleType(type));
      });
  };
};
