import React from "react";
import { useDispatch } from "react-redux";
import { Formik, Form, Field } from "formik";
import { TextField } from "formik-material-ui";
import { AddContributionSchema } from "../validationSchema";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import { FormHelperText } from "@material-ui/core";
import KeyBoardDatePicker from "../../common/datePicker/KeyboardDatePicker";
import { fetchAddContributionFunction } from "../../action/AddContributionAction";
import BoardMemberDropDown from "../../common/BoardMember";
import ContributionTypeDropDown from "../../common/ContributionType";
import { getFormattedDateWithUTC, YYYY_MM_DD } from "../../utils/dateUtils";
import { AmountNumberFormat } from "../../common/NumberFormat";
import { fetchEditContributionFunction } from "../../action/EditContributionAction";

const AddContributionForm = ({
  setHasPanelClose,
  viewFormData,
  type,
  ediFormData,
  hasPanelOpen,
  onEditPanelClose,
}) => {
  const dispatch = useDispatch();

  const onSubmit = (value) => {
    const data = {
      boardMemberId: value.boardMember,
      contributionTypeId: value.contributionType,
      description: value.description,
      amount: value.amount,
      contributionDate: getFormattedDateWithUTC(
        value.contributionDate,
        YYYY_MM_DD
      ),
    };
    if (hasPanelOpen) {
      dispatch(fetchAddContributionFunction(data));
      setHasPanelClose();
    } else {
      dispatch(fetchEditContributionFunction(data, ediFormData.id));
      onEditPanelClose();
    }
  };

  const validationMessage = {
    boardMember: "Board Member is a required",
    contributionType: "Contribution Type is a required",
    contributionDate: "Contribution Date is a required",
    description: "Description is a required ",
    amount: "Amount is a required ",
  };

  return (
    <Box>
      <Formik
        initialValues={{
          boardMember: ediFormData ? ediFormData.boardMemberId : "",
          contributionType: ediFormData ? ediFormData.contributionTypeName : "",
          contributionDate: ediFormData ? ediFormData.contributionDate : null,
          description: ediFormData ? ediFormData.description : "",
          amount: ediFormData ? ediFormData.editAmount : "",
        }}
        onSubmit={onSubmit}
        validationSchema={AddContributionSchema(validationMessage)}
        enableReinitialize
      >
        {(formikProps) => {
          const { values, errors, touched, submitForm, setFieldValue } =
            formikProps;
          return (
            <Form>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Box mb={1}>
                    <BoardMemberDropDown
                      value={values?.boardMember}
                      setDropDownObject={(value) => {
                        setFieldValue("boardMember", value?.id);
                      }}
                      touched={touched?.boardMember}
                      errors={errors?.boardMember}
                      disabled={type === "VIEW" ? true : false}
                    />
                  </Box>
                </Grid>
                <Grid item xs={6}>
                  <ContributionTypeDropDown
                    value={values?.contributionType}
                    setDropDownObject={(value) => {
                      setFieldValue("contributionType", value?.id);
                    }}
                    touched={touched?.contributionType}
                    errors={errors?.contributionType}
                    disabled={type === "VIEW" ? true : false}
                  />
                </Grid>

                <Grid item xs={6}>
                  <Box
                    className="datePickerOuter"
                    data-error={
                      touched.contributionDate && errors.contributionDate
                    }
                  >
                    <Field
                      id="contributionDate"
                      name="contributionDate"
                      value={values.contributionDate}
                      label="Contribution Date"
                      variant="outlined"
                      fullWidth
                      component={() => (
                        <KeyBoardDatePicker
                          label="Contribution Date"
                          value={values.contributionDate}
                          disabled={type === "VIEW" ? true : false}
                          onChange={(date) => {
                            if (date !== "Invalid Date") {
                              setFieldValue("contributionDate", date);
                            }
                          }}
                        />
                      )}
                    />
                    {touched.contributionDate && (
                      <FormHelperText>{errors.contributionDate}</FormHelperText>
                    )}
                  </Box>
                </Grid>

                <Grid item xs={12}>
                  <Box mt={1}>
                    <Field
                      id="description"
                      name="description"
                      type="text"
                      label="Description"
                      // value={values.password ? values.password : ""}
                      variant="outlined"
                      fullWidth
                      component={TextField}
                      helperText={touched.description && errors.description}
                      disabled={type === "VIEW" ? true : false}
                    />
                  </Box>
                </Grid>

                <Grid item xs={6}>
                  <Box my={1}>
                    <Field
                      id="amount"
                      name="amount"
                      type="text"
                      label="Amount $"
                      value={`${values.amount}`}
                      variant="outlined"
                      fullWidth
                      component={TextField}
                      helperText={touched.amount && errors.amount}
                      InputProps={{
                        inputComponent: AmountNumberFormat,
                        id: "amount",
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      disabled={type === "VIEW" ? true : false}
                    />
                  </Box>
                </Grid>
                <Grid></Grid>
                {type !== "VIEW" && (
                  <Grid item xs={12}>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={submitForm}
                    >
                      Submit
                    </Button>
                  </Grid>
                )}
              </Grid>
            </Form>
          );
        }}
      </Formik>
    </Box>
  );
};

export default AddContributionForm;
