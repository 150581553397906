import {
  OVERALL_FUND_RAISING_TOTAL_SUCCESS,
  OVERALL_FUND_RAISING_TOTAL_REQUSET,
  OVERALL_FUND_RAISING_TOTAL_FAILURE,
  OVERALL_FUND_RAISING_TOTAL_RESET,
} from "../action/OverallFundRaisingTotal";

const initState = {
  loading: false,
  isError: false,
  data: "",
};

const overallFundRaisingTotal = (state = initState, action) => {
  switch (action.type) {
    case OVERALL_FUND_RAISING_TOTAL_REQUSET:
      return {
        ...state,
        loading: true,
        isError: false,
        data: "",
      };
    case OVERALL_FUND_RAISING_TOTAL_SUCCESS:
      return {
        ...state,
        loading: false,
        isError: false,
        data: action.data,
      };
    case OVERALL_FUND_RAISING_TOTAL_FAILURE:
      return {
        ...state,
        loading: false,
        data: "",
        isError: true,
      };
    case OVERALL_FUND_RAISING_TOTAL_RESET:
      return {
        ...state,
        loading: false,
        data: "",
        isError: false,
      };

    default:
      return state;
  }
};

export default overallFundRaisingTotal;
