import React from "react";
import Box from "@mui/material/Box";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";

const KeyBoardDatePicker = ({ value, onChange, label, disabled }) => {
  return (
    <>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <Box className="datePicker">
          <KeyboardDatePicker
            disableToolbar
            variant="inline"
            inputVariant="outlined"
            format="MM/dd/yyyy"
            id="date-picker-inline"
            placeholder="mm/dd/yyyy"
            label={label}
            value={value}
            fullWidth
            onChange={(date) => onChange(date)}
            InputLabelProps={{
              shrink: true,
            }}
            disabled={disabled}
          />
        </Box>
      </MuiPickersUtilsProvider>
    </>
  );
};
export default KeyBoardDatePicker;
