import "react-widgets/styles.css";
import "./App.css";
import React, { useEffect } from "react";
import Layout from "./layout";
import { ROUTES_TEXT } from "./constants/routes";
import LoginContainer from "./containers/LoginContainer";
import DashboardContainer from "./containers/DashboardContainer";
import ContributionContainer from "./containers/ContributionContainer";
import EventContainer from "./containers/EventContainer";
import BoardMemberContainer from "./containers/BoardMemberContainer";
import AttendanceContainer from "./containers/Attendance";
import { Route, Switch, Redirect, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import ProtectedRoute from "./common/ProtectedRoute";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { loginRequest } from "./auth/authConfig";
import { useState } from "react";
import { SessionExpireDialogContainer } from "./common/SessionTimeOut";
import BoardMemberDetailContainer from "./containers/BoardMemberDetailContainer";
const App = ({ history, location }) => {
  const isAuthenticated = useIsAuthenticated();
  const { instance, accounts } = useMsal();
  const [accessToken, setAccessToken] = useState(null);
  const [idToken, setIdToken] = useState(null);
  
  useEffect(() => {
    if (isAuthenticated && accessToken) {
      sessionStorage.setItem("access_token", accessToken);
      sessionStorage.setItem("id_token", idToken);
      history?.push(
        location.pathname === ROUTES_TEXT.LOGIN ||
          location.pathname === ROUTES_TEXT.NOPATH
          ? ROUTES_TEXT.DASHBOARD
          : location.pathname
      );
    }
  }, [isAuthenticated, accessToken]);
  
  const RequestAccessToken = () => {
    const request = {
      ...loginRequest,
      account: accounts[0],
    };

    // Silently acquires an access token which is then attached to a request for Microsoft Graph data
    instance
      .acquireTokenSilent(request)
      .then((response) => {
        setAccessToken(response.accessToken);
        setIdToken(response.idToken);
      })
      .catch((e) => {
        instance.acquireTokenPopup(request).then((response) => {
          setAccessToken(response.accessToken);
          setIdToken(response.idToken);
        });
      });
  };

  useEffect(() => {
    if (isAuthenticated && !accessToken) {
      RequestAccessToken();
    }
  }, [isAuthenticated]);

  return (
    <div className="App">
      <Layout>
      <SessionExpireDialogContainer accessToken={accessToken}/>
        <Switch>
          <Redirect from={ROUTES_TEXT.NOPATH} exact to={ROUTES_TEXT.LOGIN} />
          
           <Route
            path={ROUTES_TEXT.LOGIN}
            exact
            render={(props) => <LoginContainer {...props} />}
          />
          <ProtectedRoute
            path={ROUTES_TEXT.DASHBOARD}
            exact
            render={(props) => <DashboardContainer {...props} />}
          />
          <ProtectedRoute
            path={ROUTES_TEXT.CONTRIBUTIONS}
            exact
            render={(props) => <ContributionContainer {...props} />}
          />
          <ProtectedRoute
            path={ROUTES_TEXT.EVENTS}
            render={(props) => <EventContainer {...props} />}
          />
          <ProtectedRoute
            path={ROUTES_TEXT.BOARDMEMBER}
            exact
            render={(props) => <BoardMemberContainer {...props} />}
          />
          <ProtectedRoute
            path={ROUTES_TEXT.ATTENDANCE}
            exact
            render={(props) => <AttendanceContainer {...props} />}
          />
           <ProtectedRoute
            path={ROUTES_TEXT.BOARDMEMBERDETAIL}
            exact
            render={(props) => <BoardMemberDetailContainer {...props} />}
          />
        </Switch>
      </Layout>
    </div>
  );
};

const mapStateToProps = ({ auth }) => {
  return {
    auth,
  };
};

export default withRouter(connect(mapStateToProps)(App));
