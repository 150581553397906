import React from "react";
import Login from "../../components/login/index";

export default function LoginContainer() {
  return (
    <>
      <Login />
    </>
  );
}
