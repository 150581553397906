import http from "../axios/AxiosInterceptor";

export const GET_EVENT_TYPE_REQUEST = "GET_EVENT_TYPE_REQUEST";
export const GET_EVENT_TYPE_SUCCESS = "GET_EVENT_TYPE_SUCCESS";
export const GET_EVENT_TYPE_FAILURE = "GET_EVENT_TYPE_FAILURE";
export const GET_EVENT_TYPE_RESET = "GET_EVENT_TYPE_RESET";
export const requestGetEventType = () => {
  return {
    type: GET_EVENT_TYPE_REQUEST,
  };
};

export const receiveGetEventType = (data) => {
  return {
    type: GET_EVENT_TYPE_SUCCESS,
    data,
  };
};

export const failureGetEventType = () => {
  return {
    type: GET_EVENT_TYPE_FAILURE,
  };
};

export const resetGetEvenType = () => {
  return {
    type: GET_EVENT_TYPE_RESET,
  };
};

export const fetchGetEventTypeFunction = () => {
  return (dispatch) => {
    dispatch(requestGetEventType());

    const url = process.env.REACT_APP_API_DOMAIN + `/EventType?api-version=${process.env.REACT_APP_API_VERSION}`;
    http
      .get(url)
      .then((response) => {
        const data = response.data;
        if (response.status !== 200) {
          dispatch(failureGetEventType());
        } else {
          dispatch(receiveGetEventType(data));
        }
      })
      .catch((err) => {
        dispatch(failureGetEventType());
      });
  };
};
