import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import { styled } from "@mui/material/styles";
import MuiAppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import { connect } from "react-redux";
import UserNav from "./userNav";
import { makeStyles } from "@mui/styles";
import { useIsAuthenticated } from "@azure/msal-react";

const drawerWidth = 240;

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  transition: theme.transitions.create(["margin", "width"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const useStyles = makeStyles({
  mainHeader: {
    background: "#fff !important",
    boxShadow: "0 1px 4px -1px rgba(0,0,0,.15) !important",

    "& .headerLogo": {
      display: "flex",
      "& img": {
        maxHeight: 50,
      },
    },
  },
});

const Header = ({ open, handleDrawerOpen, auth }) => {
  // const [isAuthenticated, setAuthenticated] = useState(false);

  // useEffect(() => {
  //   setAuthenticated(auth.isAccess);
  // }, [auth]);
const isAuthenticated = useIsAuthenticated();
  const classes = useStyles();

  return (
    <AppBar
      position="fixed"
      open={open}
      color="default"
      className={classes.mainHeader}
    >
      <Toolbar>
        <>
          {isAuthenticated && (
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              edge="start"
              sx={{ mr: 2, ...(open && { display: "none" }) }}
            >
              <MenuIcon />
            </IconButton>
          )}
        </>
        <Box display="flex" justifyContent="space-between" width="100%">
          <Box className="headerLogo">
            <img
              src="https://wdc.org/wp-content/uploads/2018/09/Red_home_page_logov2.png"
              alt=""
            />
          </Box>
          <Box>{isAuthenticated && <UserNav />}</Box>
        </Box>
      </Toolbar>
    </AppBar>
  );
};

const mapStateToProps = ({ auth }) => {
  return {
    auth,
  };
};
export default connect(mapStateToProps)(Header);
