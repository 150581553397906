import http from "../axios/AxiosInterceptor";

export const GET_BOARD_MEMBER_DETAIL_BYID_SUCCESS =
  "GET_BOARD_MEMBER_DETAIL_BYID_SUCCESS";
export const GET_BOARD_MEMBER_DETAIL_BYID_RESET =
  "GET_BOARD_MEMBER_DETAIL_BYID_RESET";
export const requestGetBoardMemberDetailById = (id) => {
  const resp = {};
  resp["isFetching"] = true;
  resp["isError"] = false;
  resp["id"] = id;
  return {
    type: GET_BOARD_MEMBER_DETAIL_BYID_SUCCESS,
    data: resp,
  };
};

export const receiveGetBoardMemberDetailById = (id, data) => {
  const resp = {};
  resp["isFetching"] = false;
  resp["isError"] = false;
  resp["id"] = id;
  resp["data"] = data;
  return {
    type: GET_BOARD_MEMBER_DETAIL_BYID_SUCCESS,
    data: resp,
  };
};

export const failureGetBoardMemberDetailById = (id) => {
  const resp = {};
  resp["isFetching"] = false;
  resp["isError"] = true;
  resp["id"] = id;
  return {
    type: GET_BOARD_MEMBER_DETAIL_BYID_SUCCESS,
    data: resp,
  };
};

export const resetGetBoardMemberDetailById = () => {
  return {
    type: GET_BOARD_MEMBER_DETAIL_BYID_RESET,
  };
};

export const fetchGetBoardMemberDetailById = (request) => {
  return (dispatch) => {
    dispatch(requestGetBoardMemberDetailById(request.id));
   // const url = process.env.REACT_APP_API_DOMAIN + `/BoardMember?api-version=${process.env.REACT_APP_API_VERSION}`
   
    const url =
      process.env.REACT_APP_API_DOMAIN +
      `/BoardMember/${request.id}?api-version=${process.env.REACT_APP_API_VERSION}`;
    http
      .get(url)
      .then((response) => {
        const data = response.data;
        if (response.status !== 200) {
          dispatch(failureGetBoardMemberDetailById(request.id));
        } else {
          dispatch(receiveGetBoardMemberDetailById(request.id, data));
        }
      })
      .catch((err) => {
        dispatch(failureGetBoardMemberDetailById(request.id));
      });
  };
};
