import React from "react";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { fetchBoardMembers } from "../../action/BoardMember";
import Dashboard from "../../components/Dashboard";

export default function DashboardContainer() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchBoardMembers());
  }, []);

  return <Dashboard />;
}
