import React from "react";
import { connect } from "react-redux";
import { DropDownList } from "../dropDown";
import { FormHelperText } from "@mui/material";
import { fetchBoardMembers } from "../../action/BoardMember";

class BoardMemberDropDown extends React.Component {
  state = { list: [], data: [] };

  componentDidMount() {
    this.getList(this.props);
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    this.getList(newProps);
  }

  getList(props) {
    const { boardMember, dispatch, setDropDownObject, value, type } = props;
    if (boardMember && !boardMember.isError) {
      if (!boardMember.isFetching) {
        if (boardMember.data) {
          if (boardMember.data !== this.state.data) {
            const boardList = this.setDropDownOptions(boardMember.data);
            this.setState({ list: boardList, data: boardMember.data });
            if (value && boardList && boardList.length > 0) {
              setDropDownObject(boardList.find((item) => item.id === value));
            } else {
              if (boardList && boardList.length > 0) {
                if (type === "DASHBOARD") {
                  setDropDownObject(boardList[0]);
                } else {
                  setDropDownObject(boardList[0]);
                }
              }
            }
          }
        } else {
          dispatch(fetchBoardMembers());
        }
      }
    }
  }

  setDropDownOptions(data) {
    const { type } = this.props;
    const option =
      type === "DASHBOARD"
        ? [{ id: "All", value: "All", firstName: "", lastName: "" }]
        : [];
    [...data]?.map((item) => {
      option.push(item);
    });
    return option;
  }

  render() {
    const { value, setDropDownObject, touched, errors, disabled } = this.props;
    const { list } = this.state;
    return (
      <>
        <DropDownList
          value={value}
          handleChange={(value) => {
            setDropDownObject(value);
          }}
          dropDownData={(list || []).map(
            ({ firstName, lastName, id, value }) => {
              return {
                id,
                value: id === "All" ? value : lastName + ", " + firstName,
                firstName: firstName,
                lastName: lastName,
              };
            }
          )}
          touched={touched}
          errors={errors}
          fieldName="Board Member"
          positionTop={true}
          disabled={disabled}
        />
        {touched && <FormHelperText>{errors}</FormHelperText>}
      </>
    );
  }
}
const mapStateToProps = ({ boardMember }) => {
  return { boardMember };
};

export default connect(mapStateToProps)(BoardMemberDropDown);
