import http from "../axios/AxiosInterceptor";
import { resetGetContribution } from "./ContributionAction";

export const ADD_CONTRIBUTION_REQUEST = "ADD_CONTRIBUTION_REQUEST";
export const ADD_CONTRIBUTION_SUCCESS = "ADD_CONTRIBUTION_SUCCESS";
export const ADD_CONTRIBUTION_FAILURE = "ADD_CONTRIBUTION_FAILURE";
export const ADD_CONTRIBUTION_RESET = "ADD_CONTRIBUTION_RESET";
export const requestAddContribution = () => {
  return {
    type: ADD_CONTRIBUTION_REQUEST,
  };
};

export const receiveAddContribution = (data, status) => {
  return {
    type: ADD_CONTRIBUTION_SUCCESS,
    data,
    status,
  };
};

export const failureAddContribution = (status) => {
  return {
    type: ADD_CONTRIBUTION_FAILURE,
    status,
  };
};

export const resetAddContribution = () => {
  return {
    type: ADD_CONTRIBUTION_RESET,
  };
};

export const fetchAddContributionFunction = (request) => {
  return (dispatch) => {
    dispatch(requestAddContribution());
    const url = process.env.REACT_APP_API_DOMAIN + `/Contribution?api-version=${process.env.REACT_APP_API_VERSION}`;
    http
      .post(url, request)
      .then((response) => {
        const data = response.data;
        if (response.status !== 201) {
          dispatch(failureAddContribution(response.status));
        } else {
          dispatch(receiveAddContribution(data, response.status));
          dispatch(resetGetContribution());
        }
      })
      .catch((err) => {
        dispatch(failureAddContribution(err.response.status));
      });
  };
};
