import {
  EVENT_ROLE_TYPE_SUCCESS,
  EVENT_ROLE_TYPE_RESET,
} from "../action/EventRoleTypeAction";

const initState = {
  details: [],
};

const eventRoleType = (state = initState, action) => {
  switch (action.type) {
    case EVENT_ROLE_TYPE_SUCCESS:
      let data = state.details;
      const index =
        data && data.findIndex((item) => item.type === action.data.type);
      if (index !== -1) {
        data && data.splice(index, 1);
      }
      data && data.push(action.data);
      return {
        ...state,
        details: data,
      };

    case EVENT_ROLE_TYPE_RESET:
      return {
        ...state,
        details: [],
      };

    default:
      return state;
  }
};

export default eventRoleType;
