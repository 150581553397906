import http from "../axios/AxiosInterceptor";

export const GET_EVENT_SUCCESS = "GET_EVENT_SUCCESS";
export const GET_EVENT_RESET = "GET_EVENT_RESET";
export const requestGetEvent = (pageSize,
  pageNumber,
  orderBy,
  direction,eventYear) => {
    const resp = {};
  resp["isFetching"] = true;
  resp["isError"] = false;
  resp["pageSize"] = pageSize;
  resp["pageNumber"] = pageNumber;
  resp["orderBy"] = orderBy;
  resp["direction"] = direction;
  resp["eventYear"]=eventYear;
  return {
    type: GET_EVENT_SUCCESS,
    data: resp,
  };
};

export const receiveGetEvent = (pageSize,
  pageNumber,
  orderBy,
  direction,eventYear,data) => {
    const resp = {};
  resp["isFetching"] = false;
  resp["isError"] = false;
  resp["pageSize"] = pageSize;
  resp["data"] = data;
  resp["pageNumber"] = pageNumber;
  resp["orderBy"] = orderBy;
  resp["direction"] = direction;
  resp["eventYear"]=eventYear;
  return {
    type: GET_EVENT_SUCCESS,
    data:resp,
  };
};

export const failureGetEvent = (pageSize,
  pageNumber,
  orderBy,
  direction,eventYear) => {
    const resp = {};
  resp["isFetching"] = false;
  resp["isError"] = true;
  resp["pageSize"] = pageSize;
  resp["pageNumber"] = pageNumber;
  resp["orderBy"] = orderBy;
  resp["direction"] = direction;
  resp["eventYear"]=eventYear;
  return {
    type: GET_EVENT_SUCCESS,
    data:resp,
  };
};

export const resetGetEvent = () => {
  return {
    type: GET_EVENT_RESET,
  };
};

export const fetchGetEventFunction = (request) => {
  return (dispatch) => {
    dispatch(requestGetEvent(request.pageSize,
      request.pageNumber,
      request.orderBy,
      request.direction,
      request.eventYear,
      ));

    const url =
      process.env.REACT_APP_API_DOMAIN +
      `/Event/page/${request.pageNumber}/pagesize/${request.pageSize}?orderBy=${request.orderBy}&direction=${request.direction}&eventYear=${request.eventYear}&api-version=${process.env.REACT_APP_API_VERSION}`;
    http
      .get(url)
      .then((response) => {
        const data = response.data;
        if (response.status !== 200) {
          dispatch(failureGetEvent( request.pageSize,
            request.pageNumber,
            request.orderBy,
            request.direction,
            request.eventYear,
            ));
        } else {
          dispatch(receiveGetEvent(request.pageSize,
            request.pageNumber,
            request.orderBy,
            request.direction,
            request.eventYear,
            data));
        }
      })
      .catch((err) => {
        dispatch(failureGetEvent(request.pageSize,
          request.pageNumber,
          request.orderBy,
          request.direction,
          request.eventYear,
          ));
      });
  };
};
