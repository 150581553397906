import React from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";

const EngagementByMember = (props) => {
  const floorFig = (figure, decimals) => {
    const d = 10 ** decimals;
    return (parseInt(`${figure * d}`, 10) / d).toFixed(decimals);
  };

  return (
    <>
      <ResponsiveContainer width="99%" height={350}>
        <BarChart
          data={props.engagementByboardMember.data}
          margin={{
            top: 20,
            right: 20,
            left: 20,
            bottom: 20,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis
            dataKey="eventTypeName"
            label={{
              value: "Event Type Name",
              position: "outsideBottom",
              dy: 20,
            }}
          />
          <YAxis
            label={{
              value: "Percentage",
              position: "outsideLeft",
              angle: -90,
              dx: -40,
            }}
            tickFormatter={(tick) => `${floorFig(tick * 100, 1)}%`}
          />
          <Tooltip formatter={(value) => `${floorFig(value * 100, 1)}%`} />
          <Legend
            verticalAlign="top"
            align="right" //
            // content={renderLegend}
            wrapperStyle={{ top: -5 }}
          />
          <Bar
            dataKey="engagementPercentage"
            fill="#8884d8"
            name="Actual"
          />
          <Bar
            dataKey="ytdEngagementPercentage"
            fill="#82ca9d"
            name={"YTD Goal"}
          />
          
        </BarChart>
      </ResponsiveContainer>
    </>
  );
};

export default EngagementByMember;
