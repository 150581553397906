import http from "../axios/AxiosInterceptor";
import { resetBoardMembers } from "./BoardMember";

export const ADD_BOARD_MEMBER_REQUEST = "ADD_BOARD_MEMBER_REQUEST";
export const ADD_BOARD_MEMBER_SUCCESS = "ADD_BOARD_MEMBER_SUCCESS";
export const ADD_BOARD_MEMBER_FAILURE = "ADD_BOARD_MEMBER_FAILURE";
export const ADD_BOARD_MEMBER_RESET = "ADD_BOARD_MEMBER_RESET";
export const requestAddBoardMember = () => {
  return {
    type: ADD_BOARD_MEMBER_REQUEST,
  };
};

export const receiveAddBoardMember = (data, status) => {
  return {
    type: ADD_BOARD_MEMBER_SUCCESS,
    data,
    status,
  };
};

export const failureAddBoardMember = (status) => {
  return {
    type: ADD_BOARD_MEMBER_FAILURE,
    status,
  };
};

export const resetAddBoardMember = () => {
  return {
    type: ADD_BOARD_MEMBER_RESET,
  };
};

export const fetchAddBoardMemberFunction = (request) => {
  return (dispatch) => {
    dispatch(requestAddBoardMember());
    const url = process.env.REACT_APP_API_DOMAIN + `/BoardMember?api-version=${process.env.REACT_APP_API_VERSION}`;
    http
      .post(url, request)
      .then((response) => {
        const data = response.data;
        if (response.status !== 201) {
          dispatch(failureAddBoardMember(response.status));
        } else {
          dispatch(receiveAddBoardMember(data, response.status));
          dispatch(resetBoardMembers());
        }
      })
      .catch((err) => {
        dispatch(failureAddBoardMember(err.response.status));
      });
  };
};
