import React from "react";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Box
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";

export const CustomDialog = ({
  open,
  fullScreen,
  onClose,
  content,
  handleConfirm,
  showConfirmButton,
}) => {
  
  const { closeButton, dialogContent, dialogTitle, confirmButton } =
    content || {};

  return (
    <Dialog
      fullScreen={fullScreen}
      maxWidth="md"
      open={open}
      onClose={onClose}
      aria-labelledby="custom-dialog-title"
      aria-describedby="custom-dialog-"
    >
      <DialogTitle id="custom-dialog-title">
        <Box display="flex" justifyContent="space-between" fontWeight={600}>
        {dialogTitle}
        <IconButton aria-label="close" size="small" color="primary" onClick={onClose}>
          <CloseIcon />
        </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent className="custom-dialog">{dialogContent}</DialogContent>
      <DialogActions>
        <Box p={2} display="flex">
        <Box mr={2}>
        <Button
          onClick={handleConfirm}
          variant="contained"
          color="primary"
          autoFocus
        >
          {confirmButton}
        </Button>
        </Box>
        <Button
          onClick={onClose}
          variant={showConfirmButton ? "outlined" : "contained"}
          color="primary"
        >
          {closeButton}
        </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
};
