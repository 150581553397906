import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { fetchEngagementByBoardMembers } from "../../action/EngagementByBoardMember";
import { connect } from "react-redux";
import BoardMemberDropDown from "../../common/BoardMember";
import Box from "@mui/material/Box";
import { Typography } from "@mui/material";
import {
  getStartDateOfSelectedYear,
  getEndDateOfSelectedYear,
} from "../../utils/dateUtils";
import EngagementByMember from "./EngagementChart/EngagementByMember";
import OverallEngagement from "./EngagementChart/OverallEngagement";
import { fetchOverallBoardEngagement } from "../../action/OverallBoardEngagement";

const EngagementByBoardMember = (props) => {
  const dispatch = useDispatch();
  const [boardOption, setBoardOption] = useState(null);

  useEffect(() => {
    if (boardOption) {
      if (boardOption?.id === "All") {
        const req = {
          startDate: getStartDateOfSelectedYear(props.year),
          endDate: getEndDateOfSelectedYear(props.year),
          // memberID: boardOption?.id,
        };
        dispatch(fetchOverallBoardEngagement(req));
      } else {
        const req = {
          startDate: getStartDateOfSelectedYear(props.year),
          endDate: getEndDateOfSelectedYear(props.year),
          memberID: boardOption?.id,
        };
        dispatch(fetchEngagementByBoardMembers(req));
      }
    }
  }, [boardOption, boardOption?.id, props.year]);

  return (
    <>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems={"center"}
        pr={1}
        className="cardHead"
      >
        {boardOption && boardOption?.id === "All" ? (
          <Typography component="h2" variant="h3" className="cardTitle">
            Overall Board Engagement
          </Typography>
        ) : (
          <Typography component="h2" variant="h3" className="cardTitle">
            Engagement By Board Member{" "}
            {boardOption
              ? "(" + boardOption.lastName + ", " + boardOption.firstName + ")"
              : ""}
          </Typography>
        )}

        <Box display="flex" alignItems={"center"} minWidth={170}>
          <BoardMemberDropDown
            value={boardOption?.id}
            setDropDownObject={setBoardOption}
            type={"DASHBOARD"}
          />
        </Box>
      </Box>
      {boardOption && boardOption?.id === "All" ? (
        <OverallEngagement
          overallBoardengagement={props.overallBoardengagement}
          year={props.year}
        />
      ) : (
        <EngagementByMember
          engagementByboardMember={props.engagementByboardMember}
          year={props.year}
        />
      )}
    </>
  );
};

const mapStateToProps = ({
  engagementByboardMember,
  overallBoardengagement,
}) => {
  return { engagementByboardMember, overallBoardengagement };
};
export default connect(mapStateToProps)(EngagementByBoardMember);
