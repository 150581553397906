import http from "../axios/AxiosInterceptor";

export const EVENT_ROLE_TYPE_ID_SUCCESS = "EVENT_ROLE_TYPE_ID_SUCCESS";
export const EVENT_ROLE_TYPE_ID_RESET = "EVENT_ROLE_TYPE_ID_RESET";
export const requestEventRoleTypeId = (type, eventTypeId) => {
  const resp = {};
  resp["isFetching"] = true;
  resp["isError"] = false;
  resp["type"] = type;
  resp["eventTypeId"] = eventTypeId;
  return {
    type: EVENT_ROLE_TYPE_ID_SUCCESS,
    data: resp,
  };
};

export const receiveEventRoleTypeId = (type, data, eventTypeId) => {
  const resp = {};
  resp["isFetching"] = false;
  resp["isError"] = false;
  resp["type"] = type;
  resp["data"] = data;
  resp["eventTypeId"] = eventTypeId;
  return {
    type: EVENT_ROLE_TYPE_ID_SUCCESS,
    data: resp,
  };
};

export const failureEventRoleTypeId = (type, eventTypeId) => {
  const resp = {};
  resp["isFetching"] = false;
  resp["isError"] = true;
  resp["type"] = type;
  resp["eventTypeId"] = eventTypeId;
  return {
    type: EVENT_ROLE_TYPE_ID_SUCCESS,
    data: resp,
  };
};

export const resetEventRoleTypeId = () => {
  return {
    type: EVENT_ROLE_TYPE_ID_RESET,
  };
};

export const fetchEventRoleTypeId = (type, eventTypeId) => {
  return (dispatch) => {
    dispatch(requestEventRoleTypeId(type, eventTypeId));

    const url =
      process.env.REACT_APP_API_DOMAIN + `/EventType/${eventTypeId}/roletype?api-version=${process.env.REACT_APP_API_VERSION}`;
    http
      .get(url)
      .then((response) => {
        const data = response.data;
        if (response.status !== 200) {
          dispatch(failureEventRoleTypeId(type, eventTypeId));
        } else {
          dispatch(receiveEventRoleTypeId(type, data, eventTypeId));
        }
      })
      .catch((err) => {
        dispatch(failureEventRoleTypeId(type, eventTypeId));
      });
  };
};
