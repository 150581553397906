import http from "../axios/AxiosInterceptor";
import { resetGetEventAttendance } from "./GetEventAttendanceAction";

export const EDIT_EVENT_ATTENDANCE_REQUEST = "EDIT_EVENT_ATTENDANCE_REQUEST";
export const EDIT_EVENT_ATTENDANCE_SUCCESS = "EDIT_EVENT_ATTENDANCE_SUCCESS";
export const EDIT_EVENT_ATTENDANCE_FAILURE = "EDIT_EVENT_ATTENDANCE_FAILURE";
export const EDIT_EVENT_ATTENDANCE_RESET = "EDIT_EVENT_ATTENDANCE_RESET";
export const requestEditEventAttendance = () => {
  return {
    type: EDIT_EVENT_ATTENDANCE_REQUEST,
  };
};

export const receiveEditEventAttendance = (data, status) => {
  return {
    type: EDIT_EVENT_ATTENDANCE_SUCCESS,
    data,
    status,
  };
};

export const failureEditEventAttendance = (status) => {
  return {
    type: EDIT_EVENT_ATTENDANCE_FAILURE,
    status,
  };
};

export const resetEditEventAttendance = () => {
  return {
    type: EDIT_EVENT_ATTENDANCE_RESET,
  };
};

export const fetchEditEventAttendanceFunction = (request, id) => {
  return (dispatch) => {
    dispatch(requestEditEventAttendance());
    const url = process.env.REACT_APP_API_DOMAIN + `/Event/eventId/${id}/attendance?api-version=${process.env.REACT_APP_API_VERSION}`;
    http
      .patch(url, request)
      .then((response) => {
        const data = response.data;
        if (response.status !== 200) {
          dispatch(failureEditEventAttendance(response.status));
        } else {
          dispatch(receiveEditEventAttendance(data, response.status));
          dispatch(resetGetEventAttendance());
        }
      })
      .catch((err) => {
        dispatch(failureEditEventAttendance(err.response.status));
      });
  };
};
