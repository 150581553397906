import http from "../axios/AxiosInterceptor";
import { resetGetEvent } from "./GetEventAction";

export const EDIT_EVENT_REQUEST = "EDIT_EVENT_REQUEST";
export const EDIT_EVENT_SUCCESS = "EDIT_EVENT_SUCCESS";
export const EDIT_EVENT_FAILURE = "EDIT_EVENT_FAILURE";
export const EDIT_EVENT_RESET = "EDIT_EVENT_RESET";
export const requestEditEvent = () => {
  return {
    type: EDIT_EVENT_REQUEST,
  };
};

export const receiveEditEvent = (data, status) => {
  return {
    type: EDIT_EVENT_SUCCESS,
    data,
    status,
  };
};

export const failureEditEvent = (status) => {
  return {
    type: EDIT_EVENT_FAILURE,
    status,
  };
};

export const resetEditEvent = () => {
  return {
    type: EDIT_EVENT_RESET,
  };
};

export const fetchEditEventFunction = (request, id) => {
  return (dispatch) => {
    dispatch(requestEditEvent());
    const url = process.env.REACT_APP_API_DOMAIN + `/Event/${id}?api-version=${process.env.REACT_APP_API_VERSION}`;
    http
      .patch(url, request)
      .then((response) => {
        const data = response.data;
        if (response.status !== 200) {
          dispatch(failureEditEvent(response.status));
        } else {
          dispatch(receiveEditEvent(data, response.status));
          dispatch(resetGetEvent());
        }
      })
      .catch((err) => {
        dispatch(failureEditEvent(err.response.status));
      });
  };
};
