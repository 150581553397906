import DashboardIcon from "@mui/icons-material/Dashboard";
import EmojiPeopleIcon from "@mui/icons-material/EmojiPeople";
import EmojiEventsIcon from "@mui/icons-material/EmojiEvents";
import { ROUTES_TEXT } from "./routes";

export const sideNavigation = [
  {
    id: 0,
    name: "Dashboard",
    path: ROUTES_TEXT.DASHBOARD,
    icon: DashboardIcon,
    isVisible: true,
  },
  {
    id: 1,
    name: "Contributions",
    path: ROUTES_TEXT.CONTRIBUTIONS,
    icon: EmojiPeopleIcon,
    isVisible: true,
  },
  {
    id: 2,
    name: "Events",
    path: ROUTES_TEXT.EVENTS,
    icon: EmojiEventsIcon,
    isVisible: true,
  },
  {
    id: 3,
    name: "Board Members",
    path: ROUTES_TEXT.BOARDMEMBER,
    icon: EmojiPeopleIcon,
    isVisible: true,
  },
];
