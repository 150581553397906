import {
  GET_EVENT_ATTENDANCE_SUCCESS,
  GET_EVENT_ATTENDANCE_RESET,
} from "../action/GetEventAttendanceAction";

const initState = {
  details: [],
};

const getEventAttendanceReducer = (state = initState, action) => {
  switch (action.type) {
    case GET_EVENT_ATTENDANCE_SUCCESS:
      let data = state.details;
      const index =
        data && data.findIndex((item) => +item.id === +action.data.id);
      if (index !== -1) {
        data && data.splice(index, 1);
      }
      data && data.push(action.data);
      return {
        ...state,
        details: data,
      };

    case GET_EVENT_ATTENDANCE_RESET:
      return {
        ...state,
        details: [],
      };

    default:
      return state;
  }
};

export default getEventAttendanceReducer;
