import http from "../axios/AxiosInterceptor";
export const GET_EVENT_DETAILS_SUCCESS = "GET_EVENT_DETAILS_SUCCESS";
export const GET_EVENT_DETAILS_RESET = "GET_EVENT_DETAILS_RESET";
export const requestGetEventDetails = (id) => {
  const resp = {};
  resp["isFetching"] = true;
  resp["isError"] = false;
  resp["id"] = id;
  return {
    type: GET_EVENT_DETAILS_SUCCESS,
    data: resp,
  };
};

export const receiveGetEventDetails = (id, data) => {
  const resp = {};
  resp["isFetching"] = false;
  resp["isError"] = false;
  resp["id"] = id;
  resp["data"] = data;
  return {
    type: GET_EVENT_DETAILS_SUCCESS,
    data: resp,
  };
};

export const failureGetEventDetails = (id) => {
  const resp = {};
  resp["isFetching"] = false;
  resp["isError"] = true;
  resp["id"] = id;
  return {
    type: GET_EVENT_DETAILS_SUCCESS,
    data: resp,
  };
};

export const resetGetEventDetails = () => {
  return {
    type: GET_EVENT_DETAILS_RESET,
  };
};

export const fetchGetEventDetailsFunction = (request) => {
  return (dispatch) => {
    dispatch(requestGetEventDetails(request.id));
    const url = process.env.REACT_APP_API_DOMAIN + `/Event/${request.id}?api-version=${process.env.REACT_APP_API_VERSION}`;
    http
      .get(url)
      .then((response) => {
        const data = response.data;
        if (response.status !== 200) {
          dispatch(failureGetEventDetails(request.id));
        } else {
          dispatch(receiveGetEventDetails(request.id, data));
        }
      })
      .catch((err) => {
        dispatch(failureGetEventDetails(request.id));
      });
  };
};
