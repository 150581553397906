import http from "../axios/AxiosInterceptor";

export const GET_EVENT_ATTENDANCE_SUCCESS = "GET_EVENT_ATTENDANCE_SUCCESS";
export const GET_EVENT_ATTENDANCE_RESET = "GET_EVENT_ATTENDANCE_RESET";
export const requestGetEventAttendance = (id) => {
  const resp = {};
  resp["isFetching"] = true;
  resp["isError"] = false;
  resp["id"] = id;
  return {
    type: GET_EVENT_ATTENDANCE_SUCCESS,
    data: resp,
  };
};

export const receiveGetEventAttendance = (id, data) => {
  const resp = {};
  resp["isFetching"] = false;
  resp["isError"] = false;
  resp["id"] = id;
  resp["data"] = data;
  return {
    type: GET_EVENT_ATTENDANCE_SUCCESS,
    data: resp,
  };
};

export const failureGetEventAttendance = (id) => {
  const resp = {};
  resp["isFetching"] = false;
  resp["isError"] = true;
  resp["id"] = id;
  return {
    type: GET_EVENT_ATTENDANCE_SUCCESS,
    data: resp,
  };
};

export const resetGetEventAttendance = () => {
  return {
    type: GET_EVENT_ATTENDANCE_RESET,
  };
};

export const fetchGetEventAttendance = (request) => {
  return (dispatch) => {
    dispatch(requestGetEventAttendance(request.id));

    const url =
      process.env.REACT_APP_API_DOMAIN +
      `/Event/eventId/${request.id}/attendance?api-version=${process.env.REACT_APP_API_VERSION}`;
    http
      .get(url)
      .then((response) => {
        const data = response.data;
        if (response.status !== 200) {
          dispatch(failureGetEventAttendance(request.id));
        } else {
          dispatch(receiveGetEventAttendance(request.id, data));
        }
      })
      .catch((err) => {
        dispatch(failureGetEventAttendance(request.id));
      });
  };
};
