import http from "../axios/AxiosInterceptor";

export const OVERALL_BOARD_MEMBER_ENGAGEMENT_REQUSET =
  "OVERALL_BOARD_MEMBER_ENGAGEMENT_REQUSET";
export const OVERALL_BOARD_MEMBER_ENGAGEMENT_SUCCESS =
  "OVERALL_BOARD_MEMBER_ENGAGEMENT_SUCCESS";
export const OVERALL_BOARD_MEMBER_ENGAGEMENT_FAILURE =
  "OVERALL_BOARD_MEMBER_ENGAGEMENT_FAILURE";
export const OVERALL_BOARD_MEMBER_ENGAGEMENT_RESET =
  "OVERALL_BOARD_MEMBER_ENGAGEMENT_RESET";
export const requestOverallBoardMemberEngagement = () => {
  return {
    type: OVERALL_BOARD_MEMBER_ENGAGEMENT_REQUSET,
  };
};

export const recieveOverallBoardMemberEngagement = (data) => {
  return {
    type: OVERALL_BOARD_MEMBER_ENGAGEMENT_SUCCESS,
    data,
  };
};

export const failureOverallBoardMemberEngagement = () => {
  return {
    type: OVERALL_BOARD_MEMBER_ENGAGEMENT_FAILURE,
  };
};

export const resetOverallBoardMemberEngagement = () => {
  return {
    type: OVERALL_BOARD_MEMBER_ENGAGEMENT_RESET,
  };
};

export const fetchOverallBoardMemberEngagement = (request) => {
  return (dispatch) => {
    dispatch(requestOverallBoardMemberEngagement());
    // const url = "https://wdcboardmemberapi-dev.azurewebsites.net/report/overallboardmemberengagement";
    const url =
      process.env.REACT_APP_API_DOMAIN +
      `/Report/overallboardmemberengagement?startDate=${request.startDate}&endDate=${request.endDate}&api-version=${process.env.REACT_APP_API_VERSION}`;
    http
      .get(url)
      .then((response) => {
        const data = response.data;
        if (response.status !== 200) {
          dispatch(failureOverallBoardMemberEngagement());
        } else {
          dispatch(recieveOverallBoardMemberEngagement(data));
        }
      })
      .catch((err) => {
        dispatch(failureOverallBoardMemberEngagement());
      });
  };
};
