import {
  GET_CONTRIBUTION_SUCCESS,
  GET_CONTRIBUTION_RESET,
} from "../action/ContributionAction";

const initState = {
  paginationData: [],
};

const contributions = (state = initState, action) => {
  switch (action.type) {
    case GET_CONTRIBUTION_SUCCESS:
      let data = state.paginationData;
      const index =
        data &&
        data.findIndex(
          (item) =>
            item.pageSize === action.data.pageSize &&
            item.pageNumber === action.data.pageNumber &&
            item.direction === action.data.direction &&
            item.orderBy === action.data.orderBy &&
            item.contributionYear === action.data.contributionYear
        );
      if (index !== -1) {
        data && data.splice(index, 1);
      }
      data && data.push(action.data);
      return {
        ...state,
        paginationData: data,
      };
    case GET_CONTRIBUTION_RESET:
      return {
        ...state,
        paginationData: [],
      };

    default:
      return state;
  }
};

export default contributions;
