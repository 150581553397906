import {
  GET_BOARD_MEMBER_DETAIL_BYID_RESET,
  GET_BOARD_MEMBER_DETAIL_BYID_SUCCESS,
} from "../action/getBoardmemberDetailByIdAction";

const initState = {
  details: [],
};

const getBoardMemberDetailByIdReducer = (state = initState, action) => {
  switch (action.type) {
    case GET_BOARD_MEMBER_DETAIL_BYID_SUCCESS:
      let data = state.details;
      const index =
        data && data.findIndex((item) => +item.id === +action.data.id);
      if (index !== -1) {
        data && data.splice(index, 1);
      }
      data && data.push(action.data);
      return {
        ...state,
        details: data,
      };

    case GET_BOARD_MEMBER_DETAIL_BYID_RESET:
      return {
        ...state,
        details: [],
      };

    default:
      return state;
  }
};

export default getBoardMemberDetailByIdReducer;
