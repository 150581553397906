import { createStore, applyMiddleware } from "redux";
import { appReducer } from "../reducer";
import thinkMiddleware from "redux-thunk";

let createStoreWithMiddleWare = applyMiddleware(thinkMiddleware)(createStore);
const rootReducer = (state, action) => {
  if (action.type === "SIGNOUT") {
    state = undefined;
  }
  return appReducer(state, action);
};

const store = createStoreWithMiddleWare(
  rootReducer,
  window.__REDUX_DEVTOOLS_EXTENSIONS__ && window.__REDUX_DEVTOOLS_EXTENSIONS__()
);

export default store;
