import http from "../axios/AxiosInterceptor";

export const CONTRIBUTION_TYPE_REQUEST = "CONTRIBUTION_TYPE_REQUEST";
export const CONTRIBUTION_TYPE_SUCCESS = "CONTRIBUTION_TYPE_SUCCESS";
export const CONTRIBUTION_TYPE_FAILURE = "CONTRIBUTION_TYPE_FAILURE";
export const CONTRIBUTION_TYPE_RESET = "CONTRIBUTION_TYPE_RESET";
export const requestContributionType = () => {
  return {
    type: CONTRIBUTION_TYPE_REQUEST,
  };
};

export const receiveContributionType = (data) => {
  return {
    type: CONTRIBUTION_TYPE_SUCCESS,
    data,
  };
};

export const failureContributionType = () => {
  return {
    type: CONTRIBUTION_TYPE_FAILURE,
  };
};

export const resetContributionType = () => {
  return {
    type: CONTRIBUTION_TYPE_RESET,
  };
};

export const fetchContributionType = () => {
  return (dispatch) => {
    dispatch(requestContributionType());

    const url = process.env.REACT_APP_API_DOMAIN + `/ContributionType?api-version=${process.env.REACT_APP_API_VERSION}`;
    http
      .get(url)
      .then((response) => {
        const data = response.data;

        dispatch(receiveContributionType(data));
      })
      .catch((err) => {
        dispatch(failureContributionType());
      });
  };
};
