let timeoutId=0;
export function setIdleTimeout(
  duration,
  onIdle,
  onResume
) {
  function onExpire() {
    timeoutId = 0;
    if (onIdle) {
      onIdle();
    }
  }

  function startTimer() {
    if (timeoutId) {
      clearTimeout(timeoutId);
    }
    timeoutId = setTimeout(onExpire, duration);
  }

  function onActivity() {
    if (timeoutId) {
      clearTimeout(timeoutId);
    }
    if (onResume) {
      onResume();
    }
    setTimeout(startTimer, 1000);
  }

  window.document.addEventListener("mousemove", onActivity);
  window.document.addEventListener("keypress", onActivity);
  startTimer();

  return () => {
    window.document.removeEventListener("mousemove", onActivity);
    window.document.removeEventListener("keypress", onActivity);

    if (timeoutId) {
      clearTimeout(timeoutId);
    }
  };
}
