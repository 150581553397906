import React from "react";
import { Formik, Form, Field } from "formik";
import { TextField } from "formik-material-ui";
import { AddMemberSchema } from "../validationSchema";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import { useDispatch } from "react-redux";
import { fetchAddBoardMemberFunction } from "../../action/AddBoardMemberAction";
import { fetchEditBoardMemberFunction } from "../../action/EditBoardmemberAction";
import BoardRoleTypeDropDown from "../../common/BoardRoleType";

const AddMemberForm = ({
  setHasPanelClose,
  setHasEditPanelClose,
  ediFormData,
  hasPanelOpen,
  type,
}) => {
  const dispatch = useDispatch();

  const onSubmit = (value) => {
    const data = {
      firstName: value.firstName,
      lastName: value.lastName,
      emailAddress: value.email,
      city: value.city,
      state: value.state,
      postalCode: value.postalCode,
      boardRoleTypeKey: value.boardRoleTypeKey,
    };
    if (hasPanelOpen) {
      dispatch(fetchAddBoardMemberFunction(data));
      setHasPanelClose();
    } else {
      dispatch(fetchEditBoardMemberFunction(data, ediFormData.id));
      setHasEditPanelClose();
    }
  };

  const validationMessage = {
    firstName: "First Name is required",
    lastName: "Last Name is required",
    email: "Email is required",
  };

  return (
    <Box>
      <Formik
        initialValues={{
          firstName: ediFormData ? ediFormData.firstName : "",
          lastName: ediFormData ? ediFormData.lastName : "",
          email: ediFormData ? ediFormData.emailAddress : "",
          city: ediFormData ? ediFormData.city : "",
          state: ediFormData ? ediFormData.state : "",
          postalCode: ediFormData ? ediFormData.postalCode : "",
          boardRoleTypeKey: ediFormData ? ediFormData.boardRoleType : "",
        }}
        onSubmit={onSubmit}
        validationSchema={AddMemberSchema(validationMessage)}
      >
        {(formikProps) => {
          const { errors, touched, submitForm, setFieldValue, values } = formikProps;
          return (
            <Form>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Box >
                    <BoardRoleTypeDropDown
                      value={values?.boardRoleTypeKey}
                      setDropDownObject={(value) => {
                        setFieldValue("boardRoleTypeKey", value?.id);
                      }}
                      touched={touched?.boardRoleTypeKey}
                      errors={errors?.boardRoleTypeKey}
                      disabled={type === "VIEW" ? true : false}
                    />
                  </Box>
                </Grid>
                <Grid item xs={6}>
                  <Box mt={1}>
                    <Field
                      id="firstName"
                      name="firstName"
                      type="text"
                      label="First Name"
                      variant="outlined"
                      fullWidth
                      component={TextField}
                      helperText={touched.firstName && errors.firstName}
                      disabled={type === "VIEW" ? true : false}
                    />
                  </Box>
                </Grid>
                <Grid item xs={6}>
                  <Box mt={1}>
                    <Field
                      id="lastName"
                      name="lastName"
                      type="text"
                      label="Last Name"
                      variant="outlined"
                      fullWidth
                      component={TextField}
                      helperText={touched.lastName && errors.lastName}
                      disabled={type === "VIEW" ? true : false}
                    />
                  </Box>
                </Grid>

                <Grid item xs={6}>
                  <Box mt={1}>
                    <Field
                      id="email"
                      name="email"
                      type="text"
                      label="Email"
                      variant="outlined"
                      fullWidth
                      component={TextField}
                      helperText={touched.email && errors.email}
                      disabled={type === "VIEW" ? true : false}
                    />
                  </Box>
                </Grid>
                <Grid item xs={6}>
                  <Box mt={1}>
                    <Field
                      id="city"
                      name="city"
                      type="text"
                      label="City"
                      // value={values.password ? values.password : ""}
                      variant="outlined"
                      fullWidth
                      component={TextField}
                      helperText={touched.city && errors.city}
                      disabled={type === "VIEW" ? true : false}
                    />
                  </Box>
                </Grid>
                <Grid item xs={6}>
                  <Box my={1}>
                    <Field
                      id="state"
                      name="state"
                      type="text"
                      label="State"
                      // value={values.password ? values.password : ""}
                      variant="outlined"
                      fullWidth
                      component={TextField}
                      helperText={touched.state && errors.state}
                      disabled={type === "VIEW" ? true : false}
                    />
                  </Box>
                </Grid>
                <Grid item xs={6}>
                  <Box my={1}>
                    <Field
                      id="postalCode"
                      name="postalCode"
                      type="postalCode"
                      label="Zip Code"
                      // value={values.password ? values.password : ""}
                      variant="outlined"
                      fullWidth
                      component={TextField}
                      disabled={type === "VIEW" ? true : false}
                    />
                  </Box>
                </Grid>
                {type !== "VIEW" && (
                  <Grid item xs={12}>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={submitForm}
                    >
                      Submit
                    </Button>
                  </Grid>
                )}
              </Grid>
            </Form>
          );
        }}
      </Formik>
    </Box>
  );
};

export default AddMemberForm;
