import React, { Component } from "react";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import EngagementByBoardMember from "./EngagementByBoardMember";
import OverallBoardMemberEngagement from "./OverallBoardMemberEngagement";
import OverallFundRaisingTotal from "./OverallFundRaisingTotal";
import YearDropdown from "../../common/Year";

class Dashboard extends Component {
  state = { year: new Date().getFullYear() };

  handleYearChange = (year) => {
    this.setState({
      year: year,
    });
  };

  render() {
    const { year } = this.state;
    return (
      <>
      <Box display="flex" 
          justifyContent="space-between"
          alignItems={"flex-end"}
          pr={1} className="cardBoxDashboard aaaaaaaadasds" mr={2}>
          
          <Box></Box>
          <Box 
          className="cardHead" minWidth={170}>
           
            <YearDropdown
              value={year}
              setDropDownObject={(value) => this.handleYearChange(value?.id)}
              disabled={false}
              view={"DASHBOARD"}
            />
          
        </Box>
      </Box>
      <Grid container spacing={2}>
        
        <Grid item xs={12}>
          <Box className="cardBox" p={1.5}>
            <OverallBoardMemberEngagement year={year} />
          </Box>
        </Grid>

        <Grid item xs={12}>
          <Box className="cardBox" p={1.5}>
            <EngagementByBoardMember year={year} />
          </Box>
        </Grid>

        <Grid item xs={12}>
          <Box className="cardBox" p={1.5}>
            <OverallFundRaisingTotal year={year} />
          </Box>
        </Grid>
      </Grid>
      </>
      
    );
  }
}

export default Dashboard;
