import http from "../axios/AxiosInterceptor";
import { resetBoardMembers } from "./BoardMember";

export const EDIT_BOARD_MEMBER_REQUEST = "EDIT_BOARD_MEMBER_REQUEST";
export const EDIT_BOARD_MEMBER_SUCCESS = "EDIT_BOARD_MEMBER_SUCCESS";
export const EDIT_BOARD_MEMBER_FAILURE = "EDIT_BOARD_MEMBER_FAILURE";
export const EDIT_BOARD_MEMBER_RESET = "EDIT_BOARD_MEMBER_RESET";
export const requestEditBoardMember = () => {
  return {
    type: EDIT_BOARD_MEMBER_REQUEST,
  };
};

export const receiveEditBoardMember = (data, status) => {
  return {
    type: EDIT_BOARD_MEMBER_SUCCESS,
    data,
    status,
  };
};

export const failureEditBoardMember = (status) => {
  return {
    type: EDIT_BOARD_MEMBER_FAILURE,
    status,
  };
};

export const resetEditBoardMember = () => {
  return {
    type: EDIT_BOARD_MEMBER_RESET,
  };
};

export const fetchEditBoardMemberFunction = (request, id) => {
  return (dispatch) => {
    dispatch(requestEditBoardMember());
    const url = process.env.REACT_APP_API_DOMAIN + `/BoardMember/${id}?api-version=${process.env.REACT_APP_API_VERSION}`;
    http
      .patch(url, request)
      .then((response) => {
        const data = response.data;
        if (response.status !== 200) {
          dispatch(failureEditBoardMember(response.status));
        } else {
          dispatch(receiveEditBoardMember(data, response.status));
          dispatch(resetBoardMembers());
        }
      })
      .catch((err) => {
        dispatch(failureEditBoardMember(err.response.status));
      });
  };
};
