import React from "react";
import clsx from "clsx";
import { DropdownList } from "react-widgets/cjs";

import { Box } from "@material-ui/core";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
  dropdownOuter: {
    width: "100%",
    position: "relative",

    "&:before": {
      content: "attr(data-label)",
      fontSize: "1rem",
      lineHeight: "normal",
      color: "rgba(0, 0, 0, 0.6)",
      background: "#fff",
      padding: "0 5px",
      transformOrigin: "left center",
      transform: "translate(10px, -10px) scale(0.75) !important",
      position: "absolute",
      top: 0,
      left: 0,
      zIndex: 1,
      fontWeight: 400,
    },

    "& .rw-widget-picker": {
      minHeight: 43,
    },

    "&[data-error]": {
      "&:before": {
        color: "#d32f2f",
      },

      "& .rw-widget-picker": {
        borderColor: "#d32f2f",
      },
    },

    "& + .MuiFormHelperText-root": {
      color: "#d32f2f",
      marginLeft: 14,
    },
  },
});

export const DropDownList = ({
  value,
  handleChange,
  touched,
  dropDownData,
  errors,
  fieldName,
  positionTop,
  disabled
 
}) => {
  const classes = useStyles();

  return (
    <>
      <Box
        className={classes.dropdownOuter}
        data-label={fieldName}
        data-error={touched && errors}
      >
        <DropdownList
          dataKey="id"
          textField="value"
          data={dropDownData}
          onChange={(nextValue) => {
            handleChange(nextValue);
          }}
          value={value}
          placeholder={fieldName}
          className={clsx(
            positionTop ? "positionTop" : "positionBottom",
            touched && errors ? "rw-error" : ""
          )}
          disabled={disabled}
        />
      </Box>
    </>
  );
};
