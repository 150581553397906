import {
  BOARD_MEMBER_SUCCESS,
  BOARD_MEMBER_REQUSET,
  BOARD_MEMBER_FAILURE,
  BOARD_MEMBER_RESET,
} from "../action/BoardMember";

const initState = {
  isFetching: false,
  isError: false,
  data: "",
};

const boardMember = (state = initState, action) => {
  switch (action.type) {
    case BOARD_MEMBER_REQUSET:
      return {
        ...state,
        isFetching: true,
        isError: false,
        data: "",
      };
    case BOARD_MEMBER_SUCCESS:
      return {
        ...state,
        isFetching: false,
        isError: false,
        data: action.data,
      };
    case BOARD_MEMBER_FAILURE:
      return {
        ...state,
        isFetching: false,
        isError: true,
      };
    case BOARD_MEMBER_RESET:
      return {
        ...state,
        isFetching: false,
        data: "",
        isError: false,
      };

    default:
      return state;
  }
};

export default boardMember;
