import * as React from "react";
import Box from "@mui/material/Box";
import { Typography } from "@mui/material";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import { SlideoutPanel } from "../../common/slideOutPanel";
import AddMemberForm from "../BoardMemberList/addMembers";
import DeleteIcon from "@mui/icons-material/Delete";
import { AlertDialog } from "../../common/confirmation";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import CreateIcon from "@mui/icons-material/Create";
import Tooltip from "@mui/material/Tooltip";
import { fetchBoardMembers } from "../../action/BoardMember";
import { DataGrid } from "@mui/x-data-grid";
import { connect } from "react-redux";
import { fetchDeleteBoardMemberFunction } from "../../action/DeleteBoardMemberAction";
import { SnackBarMessage } from "../../shared-components/SnackBarMessage";
import { resetAddBoardMember } from "../../action/AddBoardMemberAction";
import { resetEditBoardMember } from "../../action/EditBoardmemberAction";
import { resetDeleteBoardMember } from "../../action/DeleteBoardMemberAction";
import {
  BOARD_MEMBER_SAVED,
  BOARD_MEMBER_UPDATED,
  BOARD_MEMBER_DELETED,
  ERROR_MESSAGE,
} from "../../common/MessageConstant";
import VisibilityOutlinedIcon from "@material-ui/icons/VisibilityOutlined";
import {  withRouter } from "react-router-dom";

class BoardMemberList extends React.Component {
  state = {
    list: [],
    hasPanelOpen: false,
    hasEditPanelOpen: false,
    openConfirmation: false,
    formattedData: [],
    ediFormData: null,
    deletedId: 0,
    hasViewPanelOpen: false,
    viewFormData: null,
  };

  componentDidMount() {
    this.getList(this.props);
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    this.getList(newProps);
  }

  getList(props) {
    const { boardMember, dispatch } = props;
    if (boardMember && !boardMember.isError) {
      if (!boardMember.isFetching) {
        if (boardMember.data) {
          //  if (boardMember.data !== this.state.list) {
          this.setState({ list: boardMember.data });
          if (boardMember.data) {
            this.setState({
              formattedData: this.setFormattedData(boardMember.data),
            });
          }
          //}
        } else {
          dispatch(fetchBoardMembers());
        }
      }
    }
  }

  setFormattedData(paginationData) {
    const formattedData = [];
    if (paginationData) {
      for (let v of paginationData) {
        const obj = { ...v, action: v.id };
        formattedData.push(obj);
      }
    }
    return formattedData;
    //   return formattedData?.length > 0 ? formattedData : rows;
  }
  onViewPanelOpen = (viewValue) => {
    this.setState({ hasViewPanelOpen: true, ediFormData: viewValue });
  };
  onViewPanelClose = () => {
    this.setState({ hasViewPanelOpen: false, ediFormData: null });
  };
  onAlertClose = () => {
    this.setState({ openConfirmation: false, deletedId: null });
  };

  onAlertOpen = (deletedId) => {
    this.setState({ openConfirmation: true, deletedId: deletedId });
  };

  setHasPanelOpen = () => {
    this.setState({ hasPanelOpen: true });
  };

  setHasPanelClose = () => {
    this.setState({ hasPanelOpen: false });
  };

  setHasEditPanelOpen = (editValue) => {
    this.setState({ hasEditPanelOpen: true, ediFormData: editValue });
  };

  setHasEditPanelClose = () => {
    this.setState({ hasEditPanelOpen: false, ediFormData: null });
  };
  getTitle = () => {
    const { hasPanelOpen, hasViewPanelOpen } = this.state;
    if (hasPanelOpen) {
      return "Add Member";
    } else if (hasViewPanelOpen) {
      return "View Member";
    } else {
      return "Edit Member ";
    }
  };
  getPanelClose = () => {
    const { hasPanelOpen, hasViewPanelOpen } = this.state;
    if (hasPanelOpen) {
      return this.setHasPanelClose.bind(this);
    } else if (hasViewPanelOpen) {
      return this.onViewPanelClose.bind(this);
    } else {
      return this.setHasEditPanelClose.bind(this);
    }
  };

  handleConfirm = (id) => {
    const { dispatch } = this.props;
    dispatch(fetchDeleteBoardMemberFunction(id));
    this.onAlertClose(false);
  };

  handleSaverityMessage = () => {
    if (
      this.props.addBoardMemberReducer.status ||
      this.props.editBoardMemberReducer.status ||
      this.props.deleteBoardMemberReducer.status
    ) {
      if (
        this.props.addBoardMemberReducer.status === 201 ||
        this.props.editBoardMemberReducer.status === 200 ||
        this.props.deleteBoardMemberReducer.status === 200
      ) {
        return "success";
      } else {
        return "error";
      }
    } else {
      return "info";
    }
  };
  handelSnackBarMessage = () => {
    if (
      this.props.addBoardMemberReducer.status ||
      this.props.editBoardMemberReducer.status ||
      this.props.deleteBoardMemberReducer.status
    ) {
      if (
        this.props.addBoardMemberReducer.status === 201 ||
        this.props.editBoardMemberReducer.status === 200 ||
        this.props.deleteBoardMemberReducer.status === 200
      ) {
        if (this.props.addBoardMemberReducer.status) {
          return BOARD_MEMBER_SAVED;
        } else if (this.props.editBoardMemberReducer.status) {
          return BOARD_MEMBER_UPDATED;
        } else {
          return BOARD_MEMBER_DELETED;
        }
      } else {
        return ERROR_MESSAGE;
      }
    }
  };
  render() {
    const {
      openConfirmation,
      hasPanelOpen,
      formattedData,
      hasEditPanelOpen,
      hasViewPanelOpen,
    } = this.state;
    const columns = [
      {
        field: "firstName",
        headerName: "First Name",
        flex: 1,
        disableColumnMenu: true,
      },
      {
        field: "lastName",
        headerName: "Last Name",
        flex: 1,
        disableColumnMenu: true,
      },
      {
        field: "boardRoleType",
        headerName: "BoardRoleType",
        flex: 1,
        hide: true,
        disableColumnMenu: true,
      },
      {
        field: "emailAddress",
        headerName: "Email",
        flex: 1,
        disableColumnMenu: true,
      },
      {
        field: "city",
        headerName: "City",
        flex: 1,
        disableColumnMenu: true,
      },

      {
        field: "state",
        headerName: "State",
        flex: 1,
        disableColumnMenu: true,
      },

      {
        field: "postalCode",
        headerName: "Zip Code",
        disableColumnMenu: true,
        flex: 1,
      },
      {
        field: "action",
        headerName: "Action",
        flex: 1,
        disableColumnMenu: true,
        sortable: false,
        renderCell: (param, props) => (
          <>
            <Tooltip title="View">
              <IconButton
                color="primary"
                aria-label="View"
                component="label"
                onClick={() =>
                  this.props.history.push(`/boardMembers/${param.value}`)
                }
              >
                <VisibilityOutlinedIcon fontSize="small" />
              </IconButton>
            </Tooltip>
            {/* <Tooltip title="View">
              <IconButton
                color="primary"
                aria-label="View"
                component="label"
               onClick={() => this.onViewPanelOpen(param.row) }
              
              >
                <VisibilityOutlinedIcon fontSize="small" />
              </IconButton>
            </Tooltip> */}
            <Tooltip title="Edit">
              <IconButton
                color="primary"
                aria-label="Edit"
                component="label"
                onClick={() => {
                  this.setHasEditPanelOpen(param.row);
                }}
              >
                <CreateIcon fontSize="small" />
              </IconButton>
            </Tooltip>

            <Tooltip title="Delete">
              <IconButton
                color="primary"
                aria-label="Delete"
                component="label"
                onClick={() => this.onAlertOpen(param.value)}
              >
                <DeleteIcon fontSize="small" />
              </IconButton>
            </Tooltip>
          </>
        ),
      },
    ];

    return (
      <Box className="cardBox" p={1.5}>
        <Box
          className="cardHead pt0"
          display="flex"
          alignItems="center"
          justifyContent="space-between"
        >
          <Typography component="h1" variant="h3" className="cardTitle">
            Board Members
          </Typography>
          <Button
            variant="outlined"
            color="primary"
            onClick={this.setHasPanelOpen.bind(this)}
            startIcon={<AddCircleOutlineIcon />}
          >
            Add Members
          </Button>
        </Box>
        <Box sx={{ height: 550, width: "100%" }}>
          <DataGrid rows={formattedData} columns={columns} />
        </Box>

        <SlideoutPanel
          open={hasPanelOpen || hasEditPanelOpen || hasViewPanelOpen}
          title={this.getTitle()}
          onClose={this.getPanelClose()}
        >
          <AddMemberForm
            setHasPanelClose={this.setHasPanelClose.bind(this)}
            setHasEditPanelClose={this.setHasEditPanelClose.bind(this)}
            ediFormData={this.state.ediFormData}
            hasPanelOpen={hasPanelOpen}
            type={hasPanelOpen || hasEditPanelOpen ? "EDIT" : "VIEW"}
          />
        </SlideoutPanel>
        <AlertDialog
          open={openConfirmation}
          onclose={this.onAlertClose.bind(this)}
          confirmButton="Ok"
          CancelButton="Cancel"
          handleConfirm={() => this.handleConfirm(this.state.deletedId)}
          content="Are you sure, you want to delete this member?"
        />

        <SnackBarMessage
          messageOpen={
            !!this.props.addBoardMemberReducer.status ||
            !!this.props.editBoardMemberReducer.status ||
            !!this.props.deleteBoardMemberReducer.status
          }
          setMessageOpen={() => {
            this.props.dispatch(resetAddBoardMember());
            this.props.dispatch(resetEditBoardMember());
            this.props.dispatch(resetDeleteBoardMember());
          }}
          severity={this.handleSaverityMessage()}
          autoHideDuration={1000}
          message={this.handelSnackBarMessage()}
        />
      </Box>
    );
  }
}

const mapStateToProps = ({
  boardMember,
  addBoardMemberReducer,
  editBoardMemberReducer,
  deleteBoardMemberReducer,
}) => {
  return {
    boardMember,
    addBoardMemberReducer,
    editBoardMemberReducer,
    deleteBoardMemberReducer,
  };
};

export default connect(mapStateToProps)(withRouter(BoardMemberList));
