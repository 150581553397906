import axios from "./axios";

axios.interceptors.request.use(
  function (config) {
    let customConfig = { ...config?.headers };
    // const accessToken = sessionStorage.getItem("access_token");
    const idToken = sessionStorage.getItem("id_token");
    if (!idToken) {
      customConfig = {
        ...customConfig,
        Accept: "application/json",
        // "Content-Type": "application/x-www-form-urlencoded",
      };
      config.headers = customConfig;
    } else if (idToken) {
      customConfig = {
        ...customConfig,
        "Content-Type": "application/json",
        Authorization: idToken && `Bearer ${idToken}`,
        "Accept-Version": process.env.REACT_APP_ACCEPT_VERSION
      };

      config.headers = customConfig;
    }

    return config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(undefined, (error) => {
  return Promise.reject(error);
});

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error && error.response) {
      const {
        response: { status },
      } = error;
      if (status === 401) {
        // logout usr
        sessionStorage.clear();
        // Router.push('/login')
      } else {
        return Promise.reject(error);
      }
    }
  }
);

export default {
  get: axios.get,
  post: axios.post,
  put: axios.put,
  delete: axios.delete,
  patch: axios.patch,
};
