import * as React from "react";
import Box from "@mui/material/Box";
import { Typography } from "@mui/material";
import Button from "@mui/material/Button";
import { SlideoutPanel } from "../../common/slideOutPanel";
import AddContributionForm from "../ContributionList/addContribution";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import DeleteIcon from "@mui/icons-material/Delete";
import { AlertDialog } from "../../common/confirmation";
import Tooltip from "@mui/material/Tooltip";
import { fetchContributionFunction } from "../../action/ContributionAction";
import { fetchDeleteContributionFunction } from "../../action/DeleteContributionACtion";
import DataTable from "../../common/DataTable";
import { connect } from "react-redux";
import IconButton from "@mui/material/IconButton";
import CreateIcon from "@mui/icons-material/Create";
import {
  amountMasking,
  getFormattedDate,
  MMDDYYYYWITHSLASH,
} from "../../utils/dateUtils";
import { SnackBarMessage } from "../../shared-components/SnackBarMessage";
import { resetAddContribution } from "../../action/AddContributionAction";
import { resetDeleteContribution } from "../../action/DeleteContributionACtion";
import {
  CONTRIBUTION_SAVED,
  CONTRIBUTION_DELETED,
  ERROR_MESSAGE,
  CONTRIBUTION_UPDATED,
} from "../../common/MessageConstant";
import { DataGrid } from "@mui/x-data-grid";
import VisibilityOutlinedIcon from "@material-ui/icons/VisibilityOutlined";
import { resetEditContribution } from "../../action/EditContributionAction";
import YearDropdown from "../../common/Year";

class ContributionList extends React.Component {
  state = {
    selectedYear: new Date().getFullYear(),
    year: new Date().getFullYear(),
    hasPanelOpen: false,
    openConfirmation: false,
    pageSize: 10,
    pageNumber: 0,
    orderBy: "boardMemberName",
    direction: "asc",
    totalPage: 0,
    paginationData: null,
    formattedData: [],
    deletedId: null,
    isLoading: false,
    sortModel: [
      {
        field: "boardMemberName",
        sort: "asc",
      },
    ],
    hasViewPanelOpen: false,
    viewFormData: null,
    hasEditPanelOpen: false,
    ediFormData: null,
  };

  componentDidMount() {
    const { pageNumber, pageSize, orderBy, direction, selectedYear } =
      this.state;
    this.getList(
      this.props,
      pageNumber + 1,
      pageSize,
      orderBy,
      direction,
      selectedYear
    );
    // this.getMetaData(this.props);
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    const { pageNumber, pageSize, orderBy, direction, selectedYear } =
      this.state;
    this.getList(
      newProps,
      pageNumber + 1,
      pageSize,
      orderBy,
      direction,
      selectedYear
    );
    // this.getMetaData(newProps);
  }
  // getMetaDataFromStore(page, data) {
  //   return data.find((item) => item.page === page);
  // }

  // getMetaData(props) {
  //   const { dataTableMetaData } = props;
  //   const { orderBy, direction ,selectedYear} = this.state;

  //   const pD = this.getMetaDataFromStore(
  //     "CONTRIBUTION",
  //     dataTableMetaData.paginationData
  //   );
  //   if (pD) {
  //     this.setState({
  //       pageNumber: pD.pageSizeValue,
  //       pageSize: pD.rowPerPageValue,
  //     });
  //     this.getList(
  //       props,
  //       pD.pageSizeValue,
  //       pD.rowPerPageValue,
  //       orderBy,
  //       direction,
  //selectedYear,
  //     );
  //   }
  // }
  getList(props, pageNumber, pageSize, orderBy, direction, contributionYear) {
    const { contributions, dispatch } = props;
    const list = this.getDataFromStore(
      pageNumber,
      pageSize,
      orderBy,
      direction,
      contributionYear,
      contributions.paginationData
    );
    if (list) {
      this.setState({
        isLoading: list.isFetching,
      });
      if (list.data) {
        this.setState({
          paginationData: list.data,
          totalPage:
            list.data && list.data.totalCount ? list.data.totalCount : 10,
          formattedData: this.setFormattedData(list.data),
        });
      }
    } else {
      const req = {
        pageNumber,
        pageSize,
        orderBy,
        direction,
        contributionYear,
      };
      dispatch(fetchContributionFunction(req));
    }
  }

  setFormattedData(paginationData) {
    const formattedData = [];
    if (paginationData) {
      for (let v of paginationData.data) {
        const obj = {
          ...v,
          amount: v?.amount ? `${amountMasking(v.amount)}` : "N/A",
          editAmount: v?.amount,
          action: v.id,
        };
        formattedData.push(obj);
      }
    }
    return formattedData;
    //   return formattedData?.length > 0 ? formattedData : rows;
  }

  getDataFromStore(
    pageNumber,
    pageSize,
    orderBy,
    direction,
    contributionYear,
    data
  ) {
    return data.find(
      (item) =>
        item.pageNumber === pageNumber &&
        item.pageSize === pageSize &&
        item.orderBy === orderBy &&
        item.direction === direction &&
        item.contributionYear === contributionYear
    );
  }
  handleYearChange = (year) => {
    const { pageSize, orderBy, direction } = this.state;
    this.setState({
      selectedYear: year,
      pageNumber: 0,
      year: year,
    });
    this.getList(this.props, 1, pageSize, orderBy, direction, year);
  };

  handleDelete = (id) => {
    this.props.dispatch(fetchDeleteContributionFunction(id));
    this.onAlertClose();
  };

  onAlertClose = () => {
    this.setState({ openConfirmation: false, deletedId: null });
  };

  onAlertOpen = (deletedId) => {
    this.setState({ openConfirmation: true, deletedId });
  };

  onViewPanelOpen = (viewValue) => {
    this.setState({ hasViewPanelOpen: true, ediFormData: viewValue });
  };
  onViewPanelClose = () => {
    this.setState({ hasViewPanelOpen: false, ediFormData: null });
  };

  onEditPanelOpen = (viewValue) => {
    this.setState({ hasEditPanelOpen: true, ediFormData: viewValue });
  };

  onEditPanelClose = () => {
    this.setState({ hasEditPanelOpen: false, ediFormData: null });
  };

  setHasPanelOpen = () => {
    this.setState({ hasPanelOpen: true });
  };

  setHasPanelClose = () => {
    this.setState({ hasPanelOpen: false });
  };

  handlePageSizeChange(pageSize) {
    const { orderBy, direction, pageNumber, selectedYear } = this.state;
    this.setState({ pageSize });
    // const { dispatch } = this.props;
    this.setState({ pageSize });
    this.getList(
      this.props,
      pageNumber + 1,
      pageSize,
      orderBy,
      direction,
      selectedYear
    );
    // dispatch(fetchDataTableMetaData("CONTRIBUTION", pageSize, pageNumber));
  }

  handlePageChange(page) {
    const { orderBy, direction, pageSize, selectedYear } = this.state;
    this.setState({ pageNumber: page });
    //const { dispatch } = this.props;
    this.setState({ pageSize });
    this.getList(
      this.props,
      page + 1,
      pageSize,
      orderBy,
      direction,
      selectedYear
    );
    // dispatch(fetchDataTableMetaData("CONTRIBUTION", pageSize, page + 1));
  }

  onHandleSortModalChange(modal) {
    const { pageSize, pageNumber, selectedYear } = this.state;
    this.setState({
      sortModel: modal,
      orderBy: modal[0].field,
      direction: modal[0].sort,
    });
    this.getList(
      this.props,
      pageNumber + 1,
      pageSize,
      modal[0].field,
      modal[0].sort,
      selectedYear
    );
  }
  getTitle = () => {
    const { hasPanelOpen, hasViewPanelOpen } = this.state;
    if (hasPanelOpen) {
      return "Add Contribution";
    } else if (hasViewPanelOpen) {
      return "View Contribution";
    } else {
      return "Edit Contribution ";
    }
  };
  getPanelClose = () => {
    const { hasPanelOpen, hasViewPanelOpen } = this.state;
    if (hasPanelOpen) {
      return this.setHasPanelClose.bind(this);
    } else if (hasViewPanelOpen) {
      return this.onViewPanelClose.bind(this);
    } else {
      return this.onEditPanelClose.bind(this);
    }
  };

  handleSaverityMessage = () => {
    if (
      this.props.addContributionReducer.status ||
      this.props.deleteContributionReducer.status ||
      !!this.props.editContributionReducer.status
    ) {
      if (
        this.props.addContributionReducer.status === 201 ||
        this.props.deleteContributionReducer.status === 200 ||
        this.props.editContributionReducer.status === 200
      ) {
        return "success";
      } else {
        return "error";
      }
    } else {
      return "info";
    }
  };
  handelSnackBarMessage = () => {
    if (
      this.props.addContributionReducer.status ||
      this.props.deleteContributionReducer.status ||
      this.props.editContributionReducer.status
    ) {
      if (
        this.props.addContributionReducer.status === 201 ||
        this.props.deleteContributionReducer.status === 200 ||
        this.props.editContributionReducer.status === 200
      ) {
        if (this.props.addContributionReducer.status) {
          return CONTRIBUTION_SAVED;
        } else if (this.props.editContributionReducer.status) {
          return CONTRIBUTION_UPDATED;
        } else {
          return CONTRIBUTION_DELETED;
        }
      } else {
        return ERROR_MESSAGE;
      }
    }
  };

  
  render() {
    const {
      hasPanelOpen,
      openConfirmation,
      pageSize,
      formattedData,
      totalPage,
      deletedId,
      isLoading,
      sortModel,
      hasViewPanelOpen,
      hasEditPanelOpen,
      pageNumber,
      year,
    } = this.state;
    const columns = [
      {
        field: "boardMemberName",
        headerName: "Board Member",
        disableColumnMenu: true,
        flex: 1,
        sortingOrder: ["desc", "asc"],
      },
      {
        field: "description",
        headerName: "Description",
        disableColumnMenu: true,
        flex: 1,
        sortingOrder: ["desc", "asc"],
      },
      {
        field: "contributionTypeName",
        headerName: "Contribution Type",
        disableColumnMenu: true,
        flex: 1,
        sortingOrder: ["desc", "asc"],
      },
      {
        field: "contributionDate",
        headerName: "Contribution Date",
        disableColumnMenu: true,
        flex: 1,
        sortingOrder: ["desc", "asc"],
        renderCell: (param) => (
          <span>{getFormattedDate(param.value, MMDDYYYYWITHSLASH)}</span>
        ),
      },
      {
        field: "amount",
        headerName: "Amount",
        type: "number",
        disableColumnMenu: true,
        sortingOrder: ["desc", "asc"],
        width: 160,
      },
      {
        field: "editAmount",
        headerName: "Amount",
        type: "number",
        disableColumnMenu: true,
        sortingOrder: ["desc", "asc"],
        width: 160,
        hide: true,
      },
      {
        field: "action",
        headerName: "Action",
        width: 160,
        disableColumnMenu: true,
        sortable: false,
        renderCell: (param) => (
          <>
            <Tooltip title="View">
              <IconButton
                color="primary"
                aria-label="View"
                component="label"
                onClick={() => this.onViewPanelOpen(param.row)}
              >
                <VisibilityOutlinedIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Edit">
              <IconButton
                color="primary"
                aria-label="Edit"
                component="label"
                onClick={() => {
                  this.onEditPanelOpen(param.row);
                }}
              >
                <CreateIcon fontSize="small" />
              </IconButton>
            </Tooltip>
            <Tooltip title="Delete">
              <IconButton
                color="primary"
                aria-label="Delete"
                component="label"
                onClick={() => this.onAlertOpen(param.value)}
              >
                <DeleteIcon fontSize="small" />
              </IconButton>
            </Tooltip>
          </>
        ),
      },
    ];

    return (
      <>
        <Box className="cardBox" p={1.5}>
          <Box
            className="cardHead pt0"
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <Typography component="h1" variant="h3" className="cardTitle">
              Contributions
            </Typography>

            <Box display="flex" alignItems="center">
              <Box mr={2}>
                <YearDropdown
                  value={year}
                  setDropDownObject={(value) =>
                    this.handleYearChange(value?.id)
                  }
                  disabled={false}
                  view={"LIST"}
                />
              </Box>

              <Button
                variant="outlined"
                color="primary"
                onClick={this.setHasPanelOpen.bind(this)}
                startIcon={<AddCircleOutlineIcon />}
              >
                Add Contributions
              </Button>
            </Box>
          </Box>
          {formattedData?.length ? (
            <DataTable
              page={pageNumber}
              rows={formattedData}
              columns={columns}
              pageSize={pageSize}
              onPageSizeChange={this.handlePageSizeChange.bind(this)}
              onPageChange={this.handlePageChange.bind(this)}
              totalPage={totalPage}
              loading={isLoading}
              sortModel={sortModel}
              onSortModelChange={this.onHandleSortModalChange.bind(this)}
            />
          ) : (
            <Box sx={{ height: 561, width: "100%" }}>
              <DataGrid rows={[]} columns={columns} loading={isLoading} />
            </Box>
          )}
          <SlideoutPanel
            open={hasPanelOpen || hasViewPanelOpen || hasEditPanelOpen}
            title={this.getTitle()}
            onClose={this.getPanelClose()}
          >
            <AddContributionForm
              setHasPanelClose={this.setHasPanelClose.bind(this)}
              onViewPanelOpen={this.onViewPanelOpen.bind(this)}
              onViewPanelClose={this.onViewPanelClose.bind(this)}
              onEditPanelClose={this.onEditPanelClose.bind(this)}
              hasPanelOpen={hasPanelOpen}
              ediFormData={this.state.ediFormData}
              type={hasPanelOpen || hasEditPanelOpen ? "EDIT" : "VIEW"}
            />
          </SlideoutPanel>
          <AlertDialog
            open={openConfirmation}
            onclose={this.onAlertClose.bind(this)}
            handleConfirm={() => this.handleDelete(deletedId)}
            confirmButton="Ok"
            CancelButton="Cancel"
            content="Are you sure, you want to delete this contribution?"
          />

          <SnackBarMessage
            messageOpen={
              !!this.props.addContributionReducer.status ||
              !!this.props.deleteContributionReducer.status ||
              !!this.props.editContributionReducer.status
            }
            setMessageOpen={() => {
              this.props.dispatch(resetAddContribution());
              this.props.dispatch(resetDeleteContribution());
              this.props.dispatch(resetEditContribution());
            }}
            severity={this.handleSaverityMessage()}
            autoHideDuration={1000}
            message={this.handelSnackBarMessage()}
          />
        </Box>
      </>
    );
  }
}

const mapStateToProps = ({
  contributions,
  addContributionReducer,
  deleteContributionReducer,
  editContributionReducer,
  // dataTableMetaData,
}) => {
  return {
    contributions,
    addContributionReducer,
    deleteContributionReducer,
    editContributionReducer,
    // dataTableMetaData,
  };
};
export default connect(mapStateToProps)(ContributionList);
