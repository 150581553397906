import React from "react";
import { connect } from "react-redux";
import { DropDownList } from "../dropDown";
import { FormHelperText } from "@mui/material";
import { fetchContributionType } from "../../action/ContributionTypeAction";

class ContributionTypeDropdown extends React.Component {
  state = { list: [] };

  componentDidMount() {
    this.getList(this.props);
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    this.getList(newProps);
  }

  getList(props) {
    const { contributionType, dispatch, setDropDownObject, value } = props;
    if (contributionType && !contributionType.isError) {
      if (!contributionType.isFetching) {
        if (contributionType.data) {
          if (contributionType.data !== this.state.list) {
            this.setState({ list: contributionType.data });
            if (
              value &&
              contributionType &&
              contributionType.data &&
              contributionType.data.length > 0
            ) {
              setDropDownObject(
                contributionType.data.find((item) => item.id === value)
              );
            } else {
              if (
                contributionType &&
                contributionType.data &&
                contributionType.data.length > 0
              ) {
                setDropDownObject(contributionType.data[0]);
              }
            }
          }
        } else {
          dispatch(fetchContributionType());
        }
      }
    }
  }

  render() {
    const { value, setDropDownObject, touched, errors, disabled } = this.props;
    const { list } = this.state;
    return (
      <>
        <DropDownList
          value={value}
          handleChange={(value) => {
            setDropDownObject(value);
          }}
          dropDownData={(list || []).map(({ name, id }) => {
            return {
              id,
              value: name,
            };
          })}
          touched={touched}
          errors={errors}
          fieldName="Contribution Type"
          positionTop={true}
          disabled={disabled}
        />
        {touched && <FormHelperText>{errors}</FormHelperText>}
      </>
    );
  }
}
const mapStateToProps = ({ contributionType }) => {
  return { contributionType };
};
export default connect(mapStateToProps)(ContributionTypeDropdown);
