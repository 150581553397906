import { SIGIN_SUCCESS, SIGIN_REQ, SIGIN_FAILURE, SIGNOUT } from "../action/auth";

const initState = {
  loading: false,
  isError: false,
  data: sessionStorage.getItem("access_token")
    ? sessionStorage.getItem("access_token")
    : "",
  isAccess: sessionStorage.getItem("access_token") ? true : false,
};

const auth = (state = initState, action) => {
  switch (action.type) {
    case SIGIN_REQ:
      return {
        ...state,
        loading: true,
        isError: false,
        data: "",
        isAccess: false,
      };
    case SIGIN_SUCCESS:
      return {
        ...state,
        loading: false,
        isError: false,
        data: action.data,
        isAccess: true,
      };
    case SIGIN_FAILURE:
      return {
        ...state,
        loading: false,
        data: "",
        isError: true,
        isAccess: false,
      };
    case SIGNOUT :
      return {
        ...state,
        loading: false,
        data: "",
        isError: false,
        isAccess: false,
      };
    default:
      return state;
  }
};

export default auth;
