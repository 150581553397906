import * as React from "react";
import Box from "@mui/material/Box";
import { Typography } from "@mui/material";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import { SlideoutPanel } from "../../common/slideOutPanel";
import AddEventForm from "../EventList/addEvent";
import DeleteIcon from "@mui/icons-material/Delete";
import { AlertDialog } from "../../common/confirmation";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import CreateIcon from "@mui/icons-material/Create";
import MoreTimeIcon from "@mui/icons-material/MoreTime";
import Tooltip from "@mui/material/Tooltip";
import { fetchGetEventFunction } from "../../action/GetEventAction";
import { connect } from "react-redux";
import DataTable from "../../common/DataTable";
import { withRouter } from "react-router-dom";
import { getFormattedDate, MMDDYYYYWITHSLASH } from "../../utils/dateUtils";
import { SnackBarMessage } from "../../shared-components/SnackBarMessage";
import { resetGetEventAdd } from "../../action/AddEventAction";
import {
  EVENT_SAVED,
  ERROR_MESSAGE,
  EVENT_DELETED,
  EVENT_UPDATED,
} from "../../common/MessageConstant";
import VisibilityOutlinedIcon from "@material-ui/icons/VisibilityOutlined";
import {
  fetchDeleteEventFunction,
  resetDeleteEvent,
} from "../../action/EventDeleteAction";
import { resetEditEvent } from "../../action/EditEventAction";
import { fetchDataTableMetaData } from "../../action/dataTableMetaDataAction";
import { DataGrid } from "@mui/x-data-grid";
import YearDropdown from "../../common/Year";

class EventList extends React.Component {
  state = {
    selectedYear: new Date().getFullYear(),
    year: new Date().getFullYear(),
    viewFormData: null,
    hasViewPanelOpen: false,
    hasPanelOpen: false,
    hasEditPanelOpen: false,
    openConfirmation: false,
    pageSize: 10,
    pageNumber: 0,
    orderBy: "eventTypeName",
    direction: "asc",
    totalPage: 0,
    paginationData: null,
    formattedData: [],
    deletedId: null,
    isLoading: false,
    sortModel: [
      {
        field: "eventTypeName",
        sort: "asc",
      },
    ],
  };

  componentDidMount() {
    const { pageNumber, pageSize, orderBy, direction, selectedYear } =
      this.state;
    this.getList(
      this.props,
      pageNumber + 1,
      pageSize,
      orderBy,
      direction,
      selectedYear
    );
    this.getMetaData(this.props);
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    const { pageNumber, pageSize, orderBy, direction, selectedYear } =
      this.state;
    this.getList(
      newProps,
      pageNumber + 1,
      pageSize,
      orderBy,
      direction,
      selectedYear
    );
    this.getMetaData(newProps);
  }

  getMetaDataFromStore(page, data) {
    return data.find((item) => item.page === page);
  }

  getMetaData(props) {
    const { dataTableMetaData } = props;
    const { orderBy, direction } = this.state;
    const pD = this.getMetaDataFromStore(
      "EVENT",
      dataTableMetaData.paginationData
    );
    if (pD) {
      this.setState({
        pageNumber: pD.pageSizeValue,
        pageSize: pD.rowPerPageValue,
        selectedYear: pD.year,
        year: pD.yearDate,
      });
      this.getList(
        props,
        pD.pageSizeValue + 1,
        pD.rowPerPageValue,
        orderBy,
        direction,
        pD.year
      );
    }
  }

  getList(props, pageNumber, pageSize, orderBy, direction, eventYear) {
    const { eventListReducer, dispatch } = props;
    const list = this.getDataFromStore(
      pageNumber,
      pageSize,
      orderBy,
      direction,
      eventYear,
      eventListReducer.paginationData
    );
    if (list) {
      this.setState({
        isLoading: list.isFetching,
      });
      if (list.data) {
        this.setState({
          paginationData: list.data,
          totalPage:
            list.data && list.data.totalCount ? list.data.totalCount : 10,
          formattedData: this.setFormattedData(list.data),
        });
      }
    } else {
      const req = {
        pageNumber,
        pageSize,
        orderBy,
        direction,
        eventYear,
      };
      dispatch(fetchGetEventFunction(req));
    }
  }

  setFormattedData(paginationData) {
    const formattedData = [];
    if (paginationData) {
      for (let v of paginationData.data) {
        const obj = { ...v, action: v.id };
        formattedData.push(obj);
      }
    }
    return formattedData;
    //   return formattedData?.length > 0 ? formattedData : rows;
  }

  getDataFromStore(pageNumber, pageSize, orderBy, direction, eventYear, data) {
    return data.find(
      (item) =>
        item.pageNumber === pageNumber &&
        item.pageSize === pageSize &&
        item.orderBy === orderBy &&
        item.direction === direction &&
        item.eventYear === eventYear
    );
  }
  handleYearChange = (year) => {
    const { pageSize, orderBy, direction } = this.state;
    this.setState({
      selectedYear: year,
      pageNumber: 0,
      year: year,
    });
    this.getList(
      this.props,
      1,
      pageSize,
      orderBy,
      direction,
      year
    );
    this.props.dispatch(
      fetchDataTableMetaData(
        "EVENT",
        pageSize,
        0,
        year,
        year
      )
    );
  };

  handleDelete = (id) => {
    this.props.dispatch(fetchDeleteEventFunction(id));
    this.onAlertClose();
  };

  onAlertClose = () => {
    this.setState({ openConfirmation: false, deletedId: null });
  };

  onAlertOpen = (deletedId) => {
    this.setState({ openConfirmation: true, deletedId });
  };
  onViewPanelOpen = (viewValue) => {
    this.setState({ hasViewPanelOpen: true, viewFormData: viewValue });
  };
  onViewPanelClose = () => {
    this.setState({ hasViewPanelOpen: false, viewFormData: null });
  };
  onEditPanelOpen = (viewValue) => {
    this.setState({ hasEditPanelOpen: true, viewFormData: viewValue });
  };
  onEditPanelClose = () => {
    this.setState({ hasEditPanelOpen: false, viewFormData: null });
  };
  setHasPanelOpen = () => {
    this.setState({ hasPanelOpen: true });
  };

  setHasPanelClose = () => {
    this.setState({ hasPanelOpen: false });
  };
  getTitle = () => {
    const { hasPanelOpen, hasViewPanelOpen } = this.state;
    if (hasPanelOpen) {
      return "Add Event";
    } else if (hasViewPanelOpen) {
      return "View Event";
    } else {
      return "Edit Event ";
    }
  };
  getPanelClose = () => {
    const { hasPanelOpen, hasViewPanelOpen } = this.state;
    if (hasPanelOpen) {
      return this.setHasPanelClose.bind(this);
    } else if (hasViewPanelOpen) {
      return this.onViewPanelClose.bind(this);
    } else {
      return this.onEditPanelClose.bind(this);
    }
  };

  handlePageSizeChange(pageSize) {
    const { orderBy, direction, pageNumber, selectedYear, year } = this.state;
    const { dispatch } = this.props;
    this.setState({ pageSize });
    this.getList(
      this.props,
      pageNumber + 1,
      pageSize,
      orderBy,
      direction,
      selectedYear
    );
    dispatch(
      fetchDataTableMetaData("EVENT", pageSize, pageNumber, selectedYear, year)
    );
  }

  handlePageChange(page) {
    const { orderBy, direction, pageSize, selectedYear, year } = this.state;
    const { dispatch } = this.props;
    this.setState({ pageNumber: page });
    this.getList(
      this.props,
      page + 1,
      pageSize,
      orderBy,
      direction,
      selectedYear
    );
    dispatch(
      fetchDataTableMetaData("EVENT", pageSize, page, selectedYear, year)
    );
  }

  onHandleSortModalChange(modal) {
    const { pageSize, pageNumber, selectedYear } = this.state;
    this.setState({
      sortModel: modal,
      orderBy: modal[0].field,
      direction: modal[0].sort,
    });
    this.getList(
      this.props,
      pageNumber + 1,
      pageSize,
      modal[0].field,
      modal[0].sort,
      selectedYear
    );
  }
  handleSaverityMessage = () => {
    if (
      this.props.addEventReducer.status ||
      this.props.editEventReducer.status ||
      this.props.deleteEventReducer.status
    ) {
      if (
        this.props.addEventReducer.status === 201 ||
        this.props.editEventReducer.status === 200 ||
        this.props.deleteEventReducer.status === 200
      ) {
        return "success";
      } else {
        return "error";
      }
    } else {
      return "info";
    }
  };
  

  handelSnackBarMessage = () => {
    if (
      this.props.addEventReducer.status ||
      this.props.editEventReducer.status ||
      this.props.deleteEventReducer.status
    ) {
      if (
        this.props.addEventReducer.status === 201 ||
        this.props.editEventReducer.status === 200 ||
        this.props.deleteEventReducer.status === 200
      ) {
        if (this.props.addEventReducer.status) {
          return EVENT_SAVED;
        } else if (this.props.editEventReducer.status) {
          return EVENT_UPDATED;
        } else {
          return EVENT_DELETED;
        }
      } else {
        return ERROR_MESSAGE;
      }
    }
  };

  render() {
    const {
      hasPanelOpen,
      openConfirmation,
      pageSize,
      formattedData,
      totalPage,
      deletedId,
      isLoading,
      sortModel,
      hasViewPanelOpen,
      hasEditPanelOpen,
      pageNumber,
      year,
    } = this.state;
    const columns = [
      {
        field: "eventTypeName",
        headerName: "Event Type Name",
        disableColumnMenu: true,
        sortingOrder: ["desc", "asc"],
        flex: 2,
      },
      {
        field: "name",
        headerName: "Name",
        disableColumnMenu: true,
        sortingOrder: ["desc", "asc"],
        flex: 1,
      },
      {
        field: "eventDate",
        headerName: "Event Date",
        disableColumnMenu: true,
        sortingOrder: ["desc", "asc"],
        flex: 1,
        renderCell: (param) => (
          <span>{getFormattedDate(param.value, MMDDYYYYWITHSLASH)}</span>
        ),
      },

      {
        field: "action",
        headerName: "Action",
        width: 170,
        disableColumnMenu: true,
        sortable: false,
        renderCell: (param) => (
          <>
            <Tooltip title="Attendance">
              <IconButton
                color="primary"
                aria-label="Attendance"
                component="label"
                onClick={() =>
                  this.props.history.push(`/attendance/${param.value}`)
                }
              >
                <MoreTimeIcon fontSize="small" />
              </IconButton>
            </Tooltip>
            <Tooltip title="View">
              <IconButton
                color="primary"
                aria-label="View"
                component="label"
                onClick={() => this.onViewPanelOpen(param.row)}
              >
                <VisibilityOutlinedIcon fontSize="small" />
              </IconButton>
            </Tooltip>
            <Tooltip title="Edit">
              <IconButton
                color="primary"
                aria-label="Edit"
                component="label"
                onClick={() => this.onEditPanelOpen(param.row)}
              >
                <CreateIcon fontSize="small" />
              </IconButton>
            </Tooltip>
            <Tooltip title="Delete">
              <IconButton
                color="primary"
                aria-label="Delete"
                component="label"
                onClick={() => this.onAlertOpen(param.value)}
              >
                <DeleteIcon fontSize="small" />
              </IconButton>
            </Tooltip>
          </>
        ),
      },
    ];

    return (
      <Box className="cardBox" p={1.5}>
        <Box
          className="cardHead pt0"
          display="flex"
          alignItems="center"
          justifyContent="space-between"
        >
          <Typography component="h1" variant="h3" className="cardTitle">
            Events
          </Typography>
          <Box display="flex" alignItems="center">
            <Box mr={2}>
              <YearDropdown
                  value={year}
                  setDropDownObject={(value) =>
                    this.handleYearChange(value?.id)
                  }
                  disabled={false}
                  view={"LIST"}
                />
            </Box>
            <Button
              variant="outlined"
              color="primary"
              onClick={this.setHasPanelOpen.bind(this)}
              startIcon={<AddCircleOutlineIcon />}
            >
              Add Event
            </Button>
          </Box>
        </Box>
        {formattedData?.length ? (
          <DataTable
            page={pageNumber}
            rows={formattedData}
            columns={columns}
            pageSize={pageSize}
            onPageSizeChange={this.handlePageSizeChange.bind(this)}
            onPageChange={this.handlePageChange.bind(this)}
            totalPage={totalPage}
            loading={isLoading}
            sortModel={sortModel}
            onSortModelChange={this.onHandleSortModalChange.bind(this)}
          />
        ) : (
          <Box sx={{ height: 561, width: "100%" }}>
            <DataGrid rows={[]} columns={columns} loading={isLoading} />
          </Box>
        )}
        <SlideoutPanel
          open={hasPanelOpen || hasViewPanelOpen || hasEditPanelOpen}
          title={this.getTitle()}
          onClose={this.getPanelClose()}
        >
          <AddEventForm
            setHasPanelClose={this.setHasPanelClose.bind(this)}
            onEditPanelClose={this.onEditPanelClose.bind(this)}
            hasPanelOpen={hasPanelOpen}
            viewFormData={this.state.viewFormData}
            type={hasPanelOpen || hasEditPanelOpen ? "EDIT" : "VIEW"}
          />
        </SlideoutPanel>
        <AlertDialog
          open={openConfirmation}
          onclose={this.onAlertClose.bind(this)}
          handleConfirm={() => this.handleDelete(deletedId)}
          confirmButton="Ok"
          CancelButton="Cancel"
          content="Are you sure, you want to delete this event?"
        />

        <SnackBarMessage
          messageOpen={
            !!this.props.addEventReducer.status ||
            !!this.props.editEventReducer.status ||
            !!this.props.deleteEventReducer.status
          }
          setMessageOpen={() => {
            this.props.dispatch(resetGetEventAdd());
            this.props.dispatch(resetDeleteEvent());
            this.props.dispatch(resetEditEvent());
          }}
          severity={this.handleSaverityMessage()}
          autoHideDuration={1000}
          message={this.handelSnackBarMessage()}
        />
      </Box>
    );
  }
}

const mapStateToProps = ({
  eventListReducer,
  addEventReducer,
  deleteEventReducer,
  editEventReducer,
  dataTableMetaData,
}) => {
  return {
    eventListReducer,
    addEventReducer,
    deleteEventReducer,
    editEventReducer,
    dataTableMetaData,
  };
};

export default connect(mapStateToProps)(withRouter(EventList));
