import http from "../axios/AxiosInterceptor";
import { resetGetContribution } from "./ContributionAction";

export const EDIT_CONTRIBUTION_REQUEST = "EDIT_CONTRIBUTION_REQUEST";
export const EDIT_CONTRIBUTION_SUCCESS = "EDIT_CONTRIBUTION_SUCCESS";
export const EDIT_CONTRIBUTION_FAILURE = "EDIT_CONTRIBUTION_FAILURE";
export const EDIT_CONTRIBUTION_RESET = "EDIT_CONTRIBUTION_RESET";
export const requestEditContribution = () => {
  return {
    type: EDIT_CONTRIBUTION_REQUEST,
  };
};

export const receiveEditContribution = (data, status) => {
  return {
    type: EDIT_CONTRIBUTION_SUCCESS,
    data,
    status,
  };
};

export const failureEditContribution = (status) => {
  return {
    type: EDIT_CONTRIBUTION_FAILURE,
    status,
  };
};

export const resetEditContribution = () => {
  return {
    type: EDIT_CONTRIBUTION_RESET,
  };
};

export const fetchEditContributionFunction = (request, id) => {
  return (dispatch) => {
    dispatch(requestEditContribution());
    const url = process.env.REACT_APP_API_DOMAIN + `/Contribution/${id}?api-version=${process.env.REACT_APP_API_VERSION}`;
    http
      .patch(url, request)
      .then((response) => {
        const data = response.data;
        if (response.status !== 200) {
          dispatch(failureEditContribution(response.status));
        } else {
          dispatch(receiveEditContribution(data, response.status));
          dispatch(resetGetContribution());
        }
      })
      .catch((err) => {
        dispatch(failureEditContribution(err.response.status));
      });
  };
};
