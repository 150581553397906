import {
  EDIT_EVENT_REQUEST,
  EDIT_EVENT_SUCCESS,
  EDIT_EVENT_FAILURE,
  EDIT_EVENT_RESET,
} from "../action/EditEventAction";

const initState = {
  loading: false,
  isError: false,
  data: "",
  status: "",
};

const editEventReducer = (state = initState, action) => {
  switch (action.type) {
    case EDIT_EVENT_REQUEST:
      return {
        ...state,
        loading: true,
        isError: false,
        data: "",
        status: "",
      };
    case EDIT_EVENT_SUCCESS:
      return {
        ...state,
        loading: false,
        isError: false,
        data: action.data,
        status: action.status,
      };
    case EDIT_EVENT_FAILURE:
      return {
        ...state,
        loading: false,
        data: "",
        isError: true,
        status: action.status,
      };
    case EDIT_EVENT_RESET:
      return {
        ...state,
        loading: false,
        data: "",
        isError: false,
        status: "",
      };

    default:
      return state;
  }
};

export default editEventReducer;
