import http from "../axios/AxiosInterceptor";

export const OVERALL_FUND_RAISING_TOTAL_REQUSET = "OVERALL_FUND_RAISING_TOTAL_REQUSET";
export const OVERALL_FUND_RAISING_TOTAL_SUCCESS = "OVERALL_FUND_RAISING_TOTAL_SUCCESS";
export const OVERALL_FUND_RAISING_TOTAL_FAILURE = "OVERALL_FUND_RAISING_TOTAL_FAILURE";
export const OVERALL_FUND_RAISING_TOTAL_RESET = "OVERALL_FUND_RAISING_TOTAL_RESET";
export const requestOverallFundRaisingTotal = () => {
  return {
    type: OVERALL_FUND_RAISING_TOTAL_REQUSET  };
};

export const recieveOverallFundRaisingTotal = (data) => {
  return {
    type: OVERALL_FUND_RAISING_TOTAL_SUCCESS,
    data,
  };
};

export const failureOverallFundRaisingTotal = () => {
  return {
    type: OVERALL_FUND_RAISING_TOTAL_FAILURE,
  };
};

export const resetOverallFundRaisingTotal = () => {
  return {
    type: OVERALL_FUND_RAISING_TOTAL_RESET,
  };
};

export const fetchOverallFundRaisingTotal = (request) => {
  return (dispatch) => {
    dispatch(requestOverallFundRaisingTotal());
    // const url = "https://wdcboardmemberapi-dev.azurewebsites.net/report/overallfundraisingtotals";
    const url = process.env.REACT_APP_API_DOMAIN + `/Report/overallfundraisingtotals?startDate=${request.startDate}&endDate=${request.endDate}&api-version=${process.env.REACT_APP_API_VERSION}`

    http
      .get(url)
      .then((response) => {
        const data = response.data;
        if (response.status !== 200) {
          dispatch(failureOverallFundRaisingTotal());
        } else {
          dispatch(recieveOverallFundRaisingTotal(data));
        }
      })
      .catch((err) => {
        dispatch(failureOverallFundRaisingTotal());
      });
  };
};
