import {
  BOARD_ROLE_TYPE_SUCCESS,
  BOARD_ROLE_TYPE_REQUSET,
  BOARD_ROLE_TYPE_FAILURE,
  BOARD_ROLE_TYPE_RESET,
} from "../action/BoardRoleType";

const initState = {
  isFetching: false,
  isError: false,
  data: "",
};

const boardRoleType = (state = initState, action) => {
  switch (action.type) {
    case BOARD_ROLE_TYPE_REQUSET:
      return {
        ...state,
        isFetching: true,
        isError: false,
        data: "",
      };
    case BOARD_ROLE_TYPE_SUCCESS:
      return {
        ...state,
        isFetching: false,
        isError: false,
        data: action.data,
      };
    case BOARD_ROLE_TYPE_FAILURE:
      return {
        ...state,
        isFetching: false,
        isError: true,
      };
    case BOARD_ROLE_TYPE_RESET:
      return {
        ...state,
        isFetching: false,
        data: "",
        isError: false,
      };

    default:
      return state;
  }
};

export default boardRoleType;
