import http from "../axios/AxiosInterceptor";

export const ENGAGEMENT_BY_BOARD_MEMBER_REQUSET = "ENGAGEMENT_BY_BOARD_MEMBER_REQUSET";
export const ENGAGEMENT_BY_BOARD_MEMBER_SUCCESS = "ENGAGEMENT_BY_BOARD_MEMBER_SUCCESS";
export const ENGAGEMENT_BY_BOARD_MEMBER_FAILURE = "ENGAGEMENT_BY_BOARD_MEMBER_FAILURE";
export const ENGAGEMENT_BY_BOARD_MEMBER_RESET = "ENGAGEMENT_BY_BOARD_MEMBER_RESET";
export const requestEngagementByBoardMembers = () => {
  return {
    type: ENGAGEMENT_BY_BOARD_MEMBER_REQUSET  };
};

export const recieveEngagementByBoardMembers = (data) => {
  return {
    type: ENGAGEMENT_BY_BOARD_MEMBER_SUCCESS,
    data,
  };
};

export const failureEngagementByBoardMembers = () => {
  return {
    type: ENGAGEMENT_BY_BOARD_MEMBER_FAILURE,
  };
};

export const resetEngagementByBoardMembers = () => {
  return {
    type: ENGAGEMENT_BY_BOARD_MEMBER_RESET,
  };
};

export const fetchEngagementByBoardMembers = (request) => {
  return (dispatch) => {
    dispatch(requestEngagementByBoardMembers());
    // const url = "https://wdcboardmemberapi-dev.azurewebsites.net/report/engagementbyboardmember";
    const url = process.env.REACT_APP_API_DOMAIN + `/Report/engagementbyboardmember/${request.memberID}?startDate=${request.startDate}&endDate=${request.endDate}&api-version=${process.env.REACT_APP_API_VERSION}`
    http
      .get(url)
      .then((response) => {
        const data = response.data;
        if (response.status !== 200) {
          dispatch(failureEngagementByBoardMembers());
        } else {
          dispatch(recieveEngagementByBoardMembers(data));
        }
      })
      .catch((err) => {
        dispatch(failureEngagementByBoardMembers());
      });
  };
};
