import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { Typography } from "@mui/material";
import Button from "@mui/material/Button";

import { fetchGetEventDetailsFunction } from "../../action/EventDetailsAction";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { fetchBoardMembers } from "../../action/BoardMember";
import { getFormattedDate, MMDDYYYYWITHSLASH } from "../../utils/dateUtils";
import AttendanceRow from "./attendanceRow";
import { fetchGetEventAttendance } from "../../action/GetEventAttendanceAction";

import {
  fetchEditEventAttendanceFunction,
  resetEditEventAttendance,
} from "../../action/EditEventAttendanceAction";
import { SnackBarMessage } from "../../shared-components/SnackBarMessage";
import { EVENT_ATTENDANCE, ERROR_MESSAGE } from "../../common/MessageConstant";
import { fetchEventRoleTypeId } from "../../action/EventTypeIdAction";

class AttendanceList extends React.Component {
  state = {
    eventDetails: null,
    isLoading: false,
    list: [],
    formData: [],
    attendanceDetails: null,
    eventList: null,
  };

  componentDidMount() {
    this.getDetails(this.props);
    this.getList(this.props);
    // this.getEventList(this.props);
    this.getEventAttendanceDetails(this.props);
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    this.getDetails(newProps);
    this.getList(newProps);
    // this.getEventList(newProps);
    this.getEventAttendanceDetails(newProps);
  }

  getDetails(props) {
    const {
      getEventDetailsReducer,
      match: { params },
      dispatch,
    } = props;
    const details = this.getDataFromStore(
      params.eventId,
      getEventDetailsReducer.details
    );
    if (details) {
      if (details.data) {
        this.setState({
          eventDetails: details.data,
        });
        this.getEventList(details.data.eventTypeId, props);
      }
    } else {
      const req = {
        id: params.eventId,
      };
      dispatch(fetchGetEventDetailsFunction(req));
    }
  }

  getEventAttendanceDetails(props) {
    const {
      getEventAttendanceReducer,
      match: { params },
      dispatch,
    } = props;
    const details = this.getDataFromStore(
      params.eventId,
      getEventAttendanceReducer.details
    );
    if (details) {
      if (details.data) {
        this.setState({
          attendanceDetails: details.data,
          formData: details.data,
        });
      }
    } else {
      const req = {
        id: params.eventId,
      };
      dispatch(fetchGetEventAttendance(req));
    }
  }

  getEventList(eventTypeId, props) {
    const { eventRoleTypeId, dispatch } = props;
    const details = this.getDataFromStoreForEvent(
      "EVENTROLETYPE",
      eventTypeId,
      eventRoleTypeId.details
    );
    if (details) {
      if (details.data) {
        this.setState({
          eventList: details.data,
        });
      }
    } else {
      dispatch(fetchEventRoleTypeId("EVENTROLETYPE", eventTypeId));
    }
  }

  getList(props) {
    const { boardMember, dispatch } = props;
    if (boardMember && !boardMember.isError) {
      if (!boardMember.isFetching) {
        if (boardMember.data) {
          if (
            JSON.stringify(boardMember.data) !== JSON.stringify(this.state.list)
          ) {
            // this.setState({ list: boardMember.data });
            this.setState({ list: boardMember.data });
          }
        } else {
          dispatch(fetchBoardMembers());
        }
      }
    }
  }

  getDataFromStore(id, data) {
    return data.find((item) => +item.id === +id);
  }

  getDataFromStoreForEvent(type, eventTypeId, data) {
    return data.find(
      (item) => item.type === type && item.eventTypeId === eventTypeId
    );
  }

  getInnerValues(innerRowObject) {
    const { formData } = this.state;
    if (innerRowObject) {
      const index =
        formData &&
        formData.findIndex(
          (item) => item.boardMemberId === innerRowObject.boardMemberId
        );
      if (index !== -1) {
        formData.splice(index, 1);
      }
      formData.push(innerRowObject);
    }
    this.setState({ formData });
  }

  save = () => {
    const {
      match: { params },
      dispatch,
    } = this.props;
    dispatch(
      fetchEditEventAttendanceFunction(this.state.formData, params.eventId)
    );
  };

  handleSaverityMessage = () => {
    if (this.props.editEventAttendanceReducer.status) {
      if (this.props.editEventAttendanceReducer.status === 200) {
        return "success";
      } else {
        return "error";
      }
    } else {
      return "info";
    }
  };
  handelSnackBarMessage = () => {
    if (this.props.editEventAttendanceReducer.status) {
      if (this.props.editEventAttendanceReducer.status === 200) {
        return EVENT_ATTENDANCE;
      } else {
        return ERROR_MESSAGE;
      }
    }
  };
  render() {
    const { eventDetails, list, attendanceDetails, eventList } = this.state;
    if (list && list.length > 0) {
      return (
        <>
          <Box className="cardBox" p={1.5} mb={2}>
            <Box className="cardHead">
              <Typography component="h1" variant="h3" className="cardTitle">
                Attendance
              </Typography>
            </Box>
            <Box my={2} textAlign="left" color="#000">
              <Grid container spacing={2}>
                <Grid item xs={3}>
                  <Box fontWeight={"bold"}>
                    {eventDetails?.name ? eventDetails?.name : ""}
                  </Box>
                </Grid>
                <Grid item xs={3}>
                  <Box fontWeight={"bold"}>
                    {eventDetails?.eventTypeName
                      ? eventDetails?.eventTypeName
                      : ""}
                  </Box>
                </Grid>
                <Grid item xs={3}>
                  <Box fontWeight={"bold"}>
                    {eventDetails?.eventDate
                      ? getFormattedDate(
                          eventDetails?.eventDate,
                          MMDDYYYYWITHSLASH
                        )
                      : ""}
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Box>

          <Box className="cardBox" p={1.5}>
            <TableContainer className="heightAuto">
              <Table sx={{ minWidth: 700 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>Board Member</TableCell>
                    <TableCell>Role</TableCell>
                    <TableCell align="right">Is Present?</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {(list || []).map((row, index) => (
                    <AttendanceRow
                      key={index}
                      row={row}
                      getInnerValues={this.getInnerValues.bind(this)}
                      attendanceData={(attendanceDetails || []).find(
                        (item) => item.boardMemberId === row.id
                      )}
                      eventList={eventList}
                    />
                  ))}
                </TableBody>
              </Table>
            </TableContainer>

            <Box textAlign={"left"} mb={1} pl={1}>
              <Button
                variant="contained"
                color="primary"
                type="submit"
                onClick={() => this.save()}
              >
                Save
              </Button>
            </Box>
            <SnackBarMessage
              messageOpen={!!this.props.editEventAttendanceReducer.status}
              setMessageOpen={() => {
                this.props.dispatch(resetEditEventAttendance());
              }}
              severity={this.handleSaverityMessage()}
              autoHideDuration={1000}
              message={this.handelSnackBarMessage()}
            />
          </Box>
        </>
      );
    } else {
      return null;
    }
  }
}

const mapStateToProps = ({
  getEventDetailsReducer,
  boardMember,
  getEventAttendanceReducer,
  eventRoleType,
  editEventAttendanceReducer,
  eventRoleTypeId,
}) => {
  return {
    getEventDetailsReducer,
    boardMember,
    eventRoleType,
    getEventAttendanceReducer,
    editEventAttendanceReducer,
    eventRoleTypeId,
  };
};

export default connect(mapStateToProps)(withRouter(AttendanceList));
