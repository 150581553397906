import React from "react";
import { DropDownList } from "../dropDown";

class YearDropdown extends React.Component {
  state = { list: [] };

  componentDidMount() {
    const list = this.getYear();
    this.setState({ list });
  }

  getYear() {
    const {view} = this.props
    let years = [];
    if(view==="DASHBOARD") {
      let maxYear = new Date().getFullYear();
      let minYear =  new Date().getFullYear()-5;
      for (let i = minYear; i <= maxYear; i++) {
        years.push(minYear++);
      }
    } else {
      let maxYear = new Date().getFullYear()+7;
      let minYear =  new Date().getFullYear()-3;
      for (let i = minYear; i <= maxYear; i++) {
        years.push(minYear++);
      }
    }
    return years;
  }

  render() {
    const { value, setDropDownObject, disabled = false } = this.props;
    const { list } = this.state;
    return (
      <>
        <DropDownList
          value={value}
          handleChange={(value) => {
            setDropDownObject(value);
          }}
          dropDownData={(list || []).map((item) => {
            return {
              id: item,
              value: item,
            };
          })}
          fieldName="Year"
          positionTop={true}
          disabled={disabled}
        
        />
      </>
    );
  }
}

export default YearDropdown;
