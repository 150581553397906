import http from "../axios/AxiosInterceptor";
import { resetGetEvent } from "./GetEventAction";

export const DELETE_EVENT_REQUEST = "DELETE_EVENT_REQUEST";
export const DELETE_EVENT_SUCCESS = "DELETE_EVENT_SUCCESS";
export const DELETE_EVENT_FAILURE = "DELETE_EVENT_FAILURE";
export const DELETE_EVENT_RESET = "DELETE_EVENT_RESET";
export const requestDeleteEvent = () => {
  return {
    type: DELETE_EVENT_REQUEST,
  };
};

export const receiveDeleteEvent = (data, status) => {
  return {
    type: DELETE_EVENT_SUCCESS,
    data,
    status,
  };
};

export const failureDeleteEvent = (status) => {
  return {
    type: DELETE_EVENT_FAILURE,
    status,
  };
};

export const resetDeleteEvent = () => {
  return {
    type: DELETE_EVENT_RESET,
  };
};

export const fetchDeleteEventFunction = (id) => {
  return (dispatch) => {
    dispatch(requestDeleteEvent());
    const url = process.env.REACT_APP_API_DOMAIN + `/Event/${id}?api-version=${process.env.REACT_APP_API_VERSION}`;
    http
      .delete(url)
      .then((response) => {
        const data = response.data;
        if (response.status !== 200) {
          dispatch(failureDeleteEvent(response.status));
        } else {
          dispatch(receiveDeleteEvent(data, response.status));
          dispatch(resetGetEvent());
        }
      })
      .catch((err) => {
        dispatch(failureDeleteEvent(err.response.status));
      });
  };
};
