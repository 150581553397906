import * as React from "react";
import Drawer from "@mui/material/Drawer";
import Box from "@mui/material/Box";
import { Typography } from "@mui/material";

import CloseIcon from "@mui/icons-material/Close";
import IconButton from '@mui/material/IconButton';

export const SlideoutPanel = ({ open, onClose, children, title }) => {
  return (
    <Box key={"right"}>
        <Drawer anchor={"right"} open={open} onClose={onClose}>
          <Box maxWidth="450px">
          <Box display="flex" justifyContent='space-between' alignItems='center' px={3} py={1.5} borderBottom="1px solid #ccc">
            <Typography component="h1" variant="h6" className="cardTitle">
              {title}
            </Typography>
            <IconButton color="primary" aria-label="Close" component="label" onClick={onClose}>
              <CloseIcon fontSize="small" />
            </IconButton>
          </Box>

          {children && (
            <Box p={3}>
              {children}
            </Box>
          )}
          </Box>
        </Drawer>
    </Box>
  );
};
