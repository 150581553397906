import http from "../axios/AxiosInterceptor";

export const OVERALL_BOARD_ENGAGEMENT_REQUSET = "OVERALL_BOARD_ENGAGEMENT_REQUSET";
export const OVERALL_BOARD_ENGAGEMENT_SUCCESS = "OVERALL_BOARD_ENGAGEMENT_SUCCESS";
export const OVERALL_BOARD_ENGAGEMENT_FAILURE = "OVERALL_BOARD_ENGAGEMENT_FAILURE";
export const OVERALL_BOARD_ENGAGEMENT_RESET = "OVERALL_BOARD_ENGAGEMENT_RESET";
export const requestOverallBoardEngagement = () => {
  return {
    type: OVERALL_BOARD_ENGAGEMENT_REQUSET  };
};

export const recieveOverallBoardEngagement = (data) => {
  return {
    type: OVERALL_BOARD_ENGAGEMENT_SUCCESS,
    data,
  };
};

export const failureOverallBoardEngagement = () => {
  return {
    type: OVERALL_BOARD_ENGAGEMENT_FAILURE,
  };
};

export const resetOverallBoardEngagement = () => {
  return {
    type: OVERALL_BOARD_ENGAGEMENT_RESET,
  };
};

export const fetchOverallBoardEngagement = (request) => {
  return (dispatch) => {
    dispatch(requestOverallBoardEngagement());
    // const url = "https://wdcboardapi-dev.azurewebsites.net/report/overallboardengagement";
    const url = process.env.REACT_APP_API_DOMAIN + `/Report/overallboardengagement?startDate=${request.startDate}&endDate=${request.endDate}&api-version=${process.env.REACT_APP_API_VERSION}`
    http
      .get(url)
      .then((response) => {
        const data = response.data;
        if (response.status !== 200) {
          dispatch(failureOverallBoardEngagement());
        } else {
          dispatch(recieveOverallBoardEngagement(data));
        }
      })
      .catch((err) => {
        dispatch(failureOverallBoardEngagement());
      });
  };
};
