import { ROUTES_TEXT } from "../constants/routes";

export const msalConfig = {
  auth: {
    clientId: process.env.REACT_APP_CLIENTID,
    authority: process.env.REACT_APP_AUTHORITY + process.env.REACT_APP_TENANTID,
    // This is a URL (e.g. https://login.microsoftonline.com/{your tenant ID})
    // redirectUri: "http://localhost:3000/",
    redirectUri: window.location.origin + ROUTES_TEXT.DASHBOARD,
    postLogoutRedirectUri: window.location.origin + ROUTES_TEXT.LOGIN,
    // scope: [
    //   "api://b937b4e7-8c11-4e8b-82f2-307c1118bfab/tracker.all",
    //   "api://b937b4e7-8c11-4e8b-82f2-307c1118bfab/tracker.read",
    // ],
  },
  cache: {
    cacheLocation: "sessionStorage", // This configures where your cache will be stored
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  },
};

// Add scopes here for ID token to be used at Microsoft identity platform endpoints.
export const loginRequest = {
  scopes: ["User.Read"],
};

// Add the endpoints here for Microsoft Graph API services you'd like to use.
export const graphConfig = {
  graphMeEndpoint: "Enter_the_Graph_Endpoint_Here/v1.0/me",
};
