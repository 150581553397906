import React from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { Typography } from "@mui/material";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { fetchGetBoardMemberDetailById } from "../../action/getBoardmemberDetailByIdAction";
import {
  amountMasking,
  getFormattedDate,
  MMDDYYYYWITHSLASH,
} from "../../utils/dateUtils";
import { DataGrid } from "@mui/x-data-grid";

class BoardMemberDetail extends React.Component {
  state = {
    isLoading: false,
    boardMemberDetailById: null,
    contributionList: [],
    eventList: [],
  };

  componentDidMount() {
    this.getBoardMemberDetailById(this.props);
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    this.getBoardMemberDetailById(newProps);
  }

  getBoardMemberDetailById(props) {
    const {
      getBoardMemberDetailByIdReducer,
      match: { params },
      dispatch,
    } = props;
    const details = this.getDataFromStore(
      params.boardMemberId,
      getBoardMemberDetailByIdReducer.details
    );
    if (details) {
      if (details.data) {
        this.setState({
          boardMemberDetailById: details.data,
          contributionList: this.setContributionsFormattedData(
            details.data.contributions
          ),
          eventList: this.setEventsFormattedData(details.data.events),
        });
      }
    } else {
      const req = {
        id: params.boardMemberId,
      };
      dispatch(fetchGetBoardMemberDetailById(req));
    }
  }

  getDataFromStore(id, data) {
    return data.find((item) => +item.id === +id);
  }

  setContributionsFormattedData(data) {
    const formattedData = [];
    if (data) {
      for (let v of data) {
        const obj = {
          ...v,
          amount: v?.amount ? `${amountMasking(v.amount)}` : "N/A",
          editAmount: v?.amount,
          action: v.id,
        };
        formattedData.push(obj);
      }
    }
    return formattedData;
  }

  setEventsFormattedData(data) {
    const formattedData = [];
    if (data) {
      for (let v of data) {
        const obj = { ...v, action: v.id };
        formattedData.push(obj);
      }
    }
    return formattedData;
  }

  render() {
    const { boardMemberDetailById, contributionList, eventList } = this.state;
    const eventColumns = [
      {
        field: "eventTypeName",
        headerName: "Event Type Name",
        disableColumnMenu: true,
        flex: 2,
      },
      {
        field: "name",
        headerName: "Name",
        disableColumnMenu: true,
        flex: 1,
      },
      {
        field: "eventDate",
        headerName: "Event Date",
        disableColumnMenu: true,
        flex: 1,
        renderCell: (param) => (
          <span>{getFormattedDate(param.value, MMDDYYYYWITHSLASH)}</span>
        ),
      },

      // {
      //   field: "action",
      //   headerName: "Action",
      //   width: 170,
      //   disableColumnMenu: true,
      //   sortable: false,
      //   renderCell: (param) => (
      //     <>
      //       <Tooltip title="Attendance">
      //         <IconButton
      //           color="primary"
      //           aria-label="Attendance"
      //           component="label"
      //           onClick={() =>
      //             this.props.history.push(`/attendance/${param.value}`)
      //           }
      //         >
      //           <MoreTimeIcon fontSize="small" />
      //         </IconButton>
      //       </Tooltip>
      //       <Tooltip title="View">
      //         <IconButton
      //           color="primary"
      //           aria-label="View"
      //           component="label"
      //           onClick={() => this.onViewPanelOpen(param.row)}
      //         >
      //           <VisibilityOutlinedIcon fontSize="small" />
      //         </IconButton>
      //       </Tooltip>
      //       <Tooltip title="Edit">
      //         <IconButton
      //           color="primary"
      //           aria-label="Edit"
      //           component="label"
      //           onClick={() => this.onEditPanelOpen(param.row)}
      //         >
      //           <CreateIcon fontSize="small" />
      //         </IconButton>
      //       </Tooltip>
      //       <Tooltip title="Delete">
      //         <IconButton
      //           color="primary"
      //           aria-label="Delete"
      //           component="label"
      //           onClick={() => this.onAlertOpen(param.value)}
      //         >
      //           <DeleteIcon fontSize="small" />
      //         </IconButton>
      //       </Tooltip>
      //     </>
      //   ),
      // },
    ];
    const contributionsColumns = [
      {
        field: "description",
        headerName: "Description",
        disableColumnMenu: true,
        flex: 0.6,
      },
      {
        field: "contributionTypeName",
        headerName: "Contribution Type",
        disableColumnMenu: true,
        flex: 0.6,
      },
      {
        field: "contributionDate",
        headerName: "Contribution Date",
        disableColumnMenu: true,
        flex: 0.6,
        renderCell: (param) => (
          <span>{getFormattedDate(param.value, MMDDYYYYWITHSLASH)}</span>
        ),
      },
      {
        field: "amount",
        headerName: "Amount",
        type: "number",
        disableColumnMenu: true,
        width: 160,
      },
      {
        field: "editAmount",
        headerName: "Amount",
        type: "number",
        disableColumnMenu: true,
        width: 160,
        hide: true,
      },
      // {
      //   field: "action",
      //   headerName: "Action",
      //   width: 160,
      //   disableColumnMenu: true,
      //   sortable: false,
      //   renderCell: (param) => (
      //     <>
      //       <Tooltip title="View">
      //         <IconButton
      //           color="primary"
      //           aria-label="View"
      //           component="label"
      //           onClick={() => this.onViewPanelOpen(param.row)}
      //         >
      //           <VisibilityOutlinedIcon />
      //         </IconButton>
      //       </Tooltip>
      //       <Tooltip title="Edit">
      //         <IconButton
      //           color="primary"
      //           aria-label="Edit"
      //           component="label"
      //           onClick={() => {
      //             this.onEditPanelOpen(param.row);
      //           }}
      //         >
      //           <CreateIcon fontSize="small" />
      //         </IconButton>
      //       </Tooltip>
      //       <Tooltip title="Delete">
      //         <IconButton
      //           color="primary"
      //           aria-label="Delete"
      //           component="label"
      //           onClick={() => this.onAlertOpen(param.value)}
      //         >
      //           <DeleteIcon fontSize="small" />
      //         </IconButton>
      //       </Tooltip>
      //     </>
      //   ),
      // },
    ];

    return (
      <>
        <Box className="cardBox" p={1.5} mb={2}>
          <Box className="cardHead">
            <Typography component="h1" variant="h3" className="cardTitle">
              Board Member Detail
            </Typography>
          </Box>
          <Box my={2} textAlign="left" color="#000">
            <Grid container spacing={2}>
              <Grid item xs={3}>
                <Box fontWeight={"bold"}>Firstname:</Box>
                <Box>
                  {boardMemberDetailById?.firstName
                    ? boardMemberDetailById?.firstName
                    : ""}
                </Box>
              </Grid>
              <Grid item xs={3}>
                <Box fontWeight={"bold"}>Lastname:</Box>
                <Box>
                  {boardMemberDetailById?.lastName
                    ? boardMemberDetailById?.lastName
                    : ""}
                </Box>
              </Grid>
              <Grid item xs={3}>
                <Box fontWeight={"bold"}>Email:</Box>
                <Box>
                  {boardMemberDetailById?.emailAddress
                    ? boardMemberDetailById?.emailAddress
                    : ""}
                </Box>
              </Grid>
              <Grid item xs={3}>
                <Box fontWeight={"bold"}>City:</Box>
                <Box>
                  {boardMemberDetailById?.city
                    ? boardMemberDetailById?.city
                    : ""}
                </Box>
              </Grid>
              <Grid item xs={3}>
                <Box fontWeight={"bold"}>State:</Box>
                <Box>
                  {boardMemberDetailById?.state
                    ? boardMemberDetailById?.state
                    : ""}
                </Box>
              </Grid>
              <Grid item xs={3}>
                <Box fontWeight={"bold"}>Postal code:</Box>
                <Box>
                  {boardMemberDetailById?.postalCode
                    ? boardMemberDetailById?.postalCode
                    : ""}
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Box>
        <Box className="cardBox" p={1.5} mb={2}>
          <Box className="cardHead">
            <Typography component="h1" variant="h3" className="cardTitle">
              Contribution Details
            </Typography>
          </Box>
          <Box my={2} textAlign="left" color="#000">
            <Grid container spacing={2}>
              <Box p={1.5} sx={{ height: 450, width: "100%" }}>
                <DataGrid
                  rows={contributionList}
                  columns={contributionsColumns}
                />
              </Box>
            </Grid>
          </Box>
        </Box>
        <Box className="cardBox" p={1.5} mb={2}>
          <Box className="cardHead">
            <Typography component="h1" variant="h3" className="cardTitle">
              Event Details
            </Typography>
          </Box>
          <Box my={2} textAlign="left" color="#000">
            <Grid container spacing={2}>
              <Box p={1.5} sx={{ height: 450, width: "100%" }}>
                <DataGrid rows={eventList} columns={eventColumns} />
              </Box>
            </Grid>
          </Box>
        </Box>
      </>
    );
  }
}
const mapStateToProps = ({ getBoardMemberDetailByIdReducer }) => {
  return {
    getBoardMemberDetailByIdReducer,
  };
};

export default connect(mapStateToProps)(withRouter(BoardMemberDetail));

//export default BoardMemberDetail;
