import { combineReducers } from "redux";
import auth from "./auth";
import boardMember from "./BoardMember";
import engagementByboardMember from "./EngagementByBoardMember";
import overallBoardMemberengagement from "./OverallBoardMemberEngagement";
import overallFundRaisingTotal from "./OverallFundRaisingTotal";
import eventRoleType from "./EventRoleTypeReducer";
import contributionType from "./ContributionTypeReducer";
import contributions from "./ContributionReducer";
import addContributionReducer from "./AddContributionReducer";
import eventListReducer from "./GetEventReducer";
import getEventDetailsReducer from "./EventDetailsReducer";
import addEventReducer from "./AddEventReducer";
import eventType from "./EventTypeReducer";
import addBoardMemberReducer from "./AddBoardMemberReducer";
import editBoardMemberReducer from "./EditBoardMemberReducer";
import deleteBoardMemberReducer from "./DeleteBoardMemberReducer";
import deleteContributionReducer from "./DeleteContributionReducer";
import getEventAttendanceReducer from "./GetEventAttendanceReducer";
import editEventAttendanceReducer from "./EditEventAttendanceReducer";
import deleteEventReducer from "./EventDeleteReducer";
import editEventReducer from "./EditEventReducer";
import eventRoleTypeId from "./EventTypeIdReducer";
import overallBoardengagement from "./OverallBoardEngagement";
import boardRoleType from "./BoardRoletype";
import editContributionReducer from "./EditContributionReducer";
import dataTableMetaData from "./dataTableMetaDataReducer";
import getBoardMemberDetailByIdReducer from "./getBoardMemberDetailByIdReducer"
export const appReducer = combineReducers({
  auth,
  boardMember,
  eventRoleType,
  contributionType,
  engagementByboardMember,
  overallBoardMemberengagement,
  overallFundRaisingTotal,
  contributions,
  addContributionReducer,
  eventListReducer,
  getEventDetailsReducer,
  addEventReducer,
  eventType,
  addBoardMemberReducer,
  editBoardMemberReducer,
  deleteBoardMemberReducer,
  deleteContributionReducer,
  getEventAttendanceReducer,
  editEventAttendanceReducer,
  deleteEventReducer,
  editEventReducer,
  eventRoleTypeId,
  overallBoardengagement,
  boardRoleType,
  editContributionReducer,
  dataTableMetaData,
  getBoardMemberDetailByIdReducer,
});
