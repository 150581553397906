import http from "../axios/AxiosInterceptor";
import { resetBoardMembers } from "./BoardMember";

export const DELETE_BOARD_MEMBER_REQUEST = "DELETE_BOARD_MEMBER_REQUEST";
export const DELETE_BOARD_MEMBER_SUCCESS = "DELETE_BOARD_MEMBER_SUCCESS";
export const DELETE_BOARD_MEMBER_FAILURE = "DELETE_BOARD_MEMBER_FAILURE";
export const DELETE_BOARD_MEMBER_RESET = "DELETE_BOARD_MEMBER_RESET";
export const requestDeleteBoardMember = () => {
  return {
    type: DELETE_BOARD_MEMBER_REQUEST,
  };
};

export const receiveDeleteBoardMember = (data, status) => {
  return {
    type: DELETE_BOARD_MEMBER_SUCCESS,
    data,
    status,
  };
};

export const failureDeleteBoardMember = (status) => {
  return {
    type: DELETE_BOARD_MEMBER_FAILURE,
    status,
  };
};

export const resetDeleteBoardMember = () => {
  return {
    type: DELETE_BOARD_MEMBER_RESET,
  };
};

export const fetchDeleteBoardMemberFunction = (id) => {
  return (dispatch) => {
    dispatch(requestDeleteBoardMember());
    const url = process.env.REACT_APP_API_DOMAIN + `/BoardMember/${id}?api-version=${process.env.REACT_APP_API_VERSION}`;
    http
      .delete(url)
      .then((response) => {
        const data = response.data;
        if (response.status !== 200) {
          dispatch(failureDeleteBoardMember(response.status));
        } else {
          dispatch(receiveDeleteBoardMember(data, response.status));
          dispatch(resetBoardMembers());
        }
      })
      .catch((err) => {
        dispatch(failureDeleteBoardMember(err.response.status));
      });
  };
};
