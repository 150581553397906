import {
  ADD_BOARD_MEMBER_REQUEST,
  ADD_BOARD_MEMBER_SUCCESS,
  ADD_BOARD_MEMBER_FAILURE,
  ADD_BOARD_MEMBER_RESET,
} from "../action/AddBoardMemberAction";

const initState = {
  loading: false,
  isError: false,
  data: "",
  status: "",
};

const addBoardMemberReducer = (state = initState, action) => {
  switch (action.type) {
    case ADD_BOARD_MEMBER_REQUEST:
      return {
        ...state,
        loading: true,
        isError: false,
        data: "",
        status: "",
      };
    case ADD_BOARD_MEMBER_SUCCESS:
      return {
        ...state,
        loading: false,
        isError: false,
        data: action.data,
        status: action.status,
      };
    case ADD_BOARD_MEMBER_FAILURE:
      return {
        ...state,
        loading: false,
        data: "",
        isError: true,
        status: action.status,
      };
    case ADD_BOARD_MEMBER_RESET:
      return {
        ...state,
        loading: false,
        data: "",
        isError: false,
        status: "",
      };

    default:
      return state;
  }
};

export default addBoardMemberReducer;
