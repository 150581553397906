import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Typography, Box } from "@mui/material";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import { fetchOverallBoardMemberEngagement } from "../../action/OverallBoardMemberEngagement";
import { connect } from "react-redux";
import {
  getStartDateOfSelectedYear,
  getEndDateOfSelectedYear,
} from "../../utils/dateUtils";

const OverallBoardMemberEngagement = (props) => {
  const dispatch = useDispatch();
  useEffect(() => {
    const req = {
      startDate: getStartDateOfSelectedYear(props.year),
      endDate: getEndDateOfSelectedYear(props.year),
      memberID: 0,
    };
    dispatch(fetchOverallBoardMemberEngagement(req));
  }, [props.year]);

  const floorFig = (figure, decimals) => {
    const d = 10 ** decimals;
    return (parseInt(`${figure * d}`, 10) / d).toFixed(decimals);
  };

  return (
    <>
      <Box className="cardHead">
        <Typography component="h2" variant="h3" className="cardTitle">
          Overall Board Member Engagement
        </Typography>
      </Box>

      <ResponsiveContainer width="99%" height={350}>
        <BarChart
          data={props.overallBoardMemberengagement.data}
          margin={{
            top: 30,
            right: 30,
            left: 20,
            bottom: 5,
          }}
          // barSize={100}
          maxBarSize={100}
        >
          {/* <CartesianGrid strokeDasharray="3 3" /> */}
          <XAxis
            dataKey="boardMemberName"
            label={{
              value: "Board Member Name",
              position: "outsideBottom",
              dy: 15,
            }}
          />
          <YAxis
            tickFormatter={(tick) => `${floorFig(tick * 100, 1)}%`}
            label={{
              value: "Board Member Percentage",
              position: "outsideLeft",
              angle: -90,
              dx: -40,
            }}
          />
          <Tooltip formatter={(value) => `${floorFig(value * 100, 1)}%`} />
          <Legend
            verticalAlign="top"
            align="right" //
            // content={renderLegend}
            wrapperStyle={{ top: -5 }}
          />
          <Bar dataKey="boardMemberPercentage" fill="#8884d8" name={"Actual"} />
          <Bar
            dataKey="ytdEngagementPercentage"
            fill="#82ca9d"
            name={"YTD Goal"}
          />
        </BarChart>
      </ResponsiveContainer>
    </>
  );
};
const mapStateToProps = ({ overallBoardMemberengagement }) => {
  return { overallBoardMemberengagement };
};
export default connect(mapStateToProps)(OverallBoardMemberEngagement);
