import http from "../axios/AxiosInterceptor";
import { resetGetContribution } from "./ContributionAction";

export const DELETE_CONTRIBUTION_REQUEST = "DELETE_CONTRIBUTION_REQUEST";
export const DELETE_CONTRIBUTION_SUCCESS = "DELETE_CONTRIBUTION_SUCCESS";
export const DELETE_CONTRIBUTION_FAILURE = "DELETE_CONTRIBUTION_FAILURE";
export const DELETE_CONTRIBUTION_RESET = "DELETE_CONTRIBUTION_RESET";
export const requestDeleteContribution = () => {
  return {
    type: DELETE_CONTRIBUTION_REQUEST,
  };
};

export const receiveDeleteContribution = (data, status) => {
  return {
    type: DELETE_CONTRIBUTION_SUCCESS,
    data,
    status,
  };
};

export const failureDeleteContribution = (status) => {
  return {
    type: DELETE_CONTRIBUTION_FAILURE,
    status,
  };
};

export const resetDeleteContribution = () => {
  return {
    type: DELETE_CONTRIBUTION_RESET,
  };
};

export const fetchDeleteContributionFunction = (id) => {
  return (dispatch) => {
    dispatch(requestDeleteContribution());
    const url = process.env.REACT_APP_API_DOMAIN + `/Contribution/${id}?api-version=${process.env.REACT_APP_API_VERSION}`;
    http
      .delete(url)
      .then((response) => {
        const data = response.data;
        if (response.status !== 200) {
          dispatch(failureDeleteContribution(response.status));
        } else {
          dispatch(receiveDeleteContribution(data, response.status));
          dispatch(resetGetContribution());
        }
      })
      .catch((err) => {
        dispatch(failureDeleteContribution(err.response.status));
      });
  };
};
