import axios from 'axios'
import axiosRetry, { isNetworkError, isRetryableError } from 'axios-retry'

export const createAxiosClientWithRetry = (
  requestTimeOut,
  requestRetries
) => {
  const client = axios.create({
    timeout: requestTimeOut,
  })
  axiosRetry(client, {
    retries: requestRetries,
    retryCondition: (error) => isNetworkError(error) || isRetryableError(error),
  })
  return client
}
