import http from "../axios/AxiosInterceptor";

export const BOARD_ROLE_TYPE_REQUSET = "BOARD_ROLE_TYPE_REQUSET";
export const BOARD_ROLE_TYPE_SUCCESS = "BOARD_ROLE_TYPE_SUCCESS";
export const BOARD_ROLE_TYPE_FAILURE = "BOARD_ROLE_TYPE_FAILURE";
export const BOARD_ROLE_TYPE_RESET = "BOARD_ROLE_TYPE_RESET";
export const requestBoardRoleType = () => {
  return {
    type: BOARD_ROLE_TYPE_REQUSET,
  };
};

export const recieveBoardRoleType = (data) => {
  return {
    type: BOARD_ROLE_TYPE_SUCCESS,
    data,
  };
};

export const failureBoardRoleType = () => {
  return {
    type: BOARD_ROLE_TYPE_FAILURE,
  };
};

export const resetBoardRoleType = () => {
  return {
    type: BOARD_ROLE_TYPE_RESET,
  };
};

export const fetchBoardRoleType = () => {
  return (dispatch) => {
    dispatch(requestBoardRoleType());
    // const url = "https://wdcboardmemberapi-dev.azurewebsites.net/boardmember";
    const url = process.env.REACT_APP_API_DOMAIN + `/api/BoardRoleType?api-version=${process.env.REACT_APP_API_VERSION}`;
    http
      .get(url)
      .then((response) => {
        const data = response.data;
        if (response.status !== 200) {
          dispatch(failureBoardRoleType());
        } else {
          dispatch(recieveBoardRoleType(data));
        }
      })
      .catch((err) => {
        dispatch(failureBoardRoleType());
      });
  };
};
