import React from "react";
import { connect } from "react-redux";
import { DropDownList } from "../dropDown";
import { FormHelperText } from "@mui/material";
import { fetchGetEventTypeFunction } from "../../action/EventTypeAction";

class EventTypeDropdown extends React.Component {
  state = { list: [] };

  componentDidMount() {
    this.getList(this.props);
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    this.getList(newProps);
  }

  getList(props) {
    const { eventType, dispatch, setDropDownObject,value } = props;
    if (eventType && !eventType.isError) {
      if (!eventType.isFetching) {
        if (eventType.data) {
          if (eventType.data !== this.state.list) {
            this.setState({ list: eventType.data });
            if (
              value &&
              eventType &&
              eventType.data &&
              eventType.data.length > 0
            ) {
              setDropDownObject(
                eventType.data.find((item) => item.id === value)
              );
            } else {
              if (eventType && eventType.data && eventType.data.length > 0) {
                setDropDownObject(eventType.data[0]);
              }
            }
          }
        } else {
          dispatch(fetchGetEventTypeFunction());
        }
      }
    }
  }

  render() {
    const { value, setDropDownObject, touched, errors, disabled } = this.props;
    const { list } = this.state;
    return (
      <>
        <DropDownList
          value={value}
          handleChange={(value) => {
            setDropDownObject(value);
          }}
          dropDownData={(list || []).map(({ name, id }) => {
            return {
              id,
              value: name,
            };
          })}
          touched={touched}
          errors={errors}
          fieldName="Event Type"
          positionTop={true}
          disabled={disabled}
        />
        {touched && <FormHelperText>{errors}</FormHelperText>}
      </>
    );
  }
}
const mapStateToProps = ({ eventType }) => {
  return { eventType };
};
export default connect(mapStateToProps)(EventTypeDropdown);
