import React from "react";
import { connect } from "react-redux";
import { DropDownList } from "../dropDown";
import { FormHelperText } from "@mui/material";
import { fetchBoardRoleType } from "../../action/BoardRoleType";

class BoardRoleTypeDropDown extends React.Component {
  state = { list: [], data: [] };

  componentDidMount() {
    this.getList(this.props);
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    this.getList(newProps);
  }

  getList(props) {
    const { boardRoleType, dispatch, setDropDownObject, value } = props;
    if (boardRoleType && !boardRoleType.isError) {
      if (!boardRoleType.isFetching) {
        if (boardRoleType.data) {
          if (boardRoleType.data !== this.state.data) {
            const boardList = this.setDropDownOptions(boardRoleType.data);
            this.setState({ list: boardList, data: boardRoleType.data });
            if (value && boardList && boardList.length > 0) {
              setDropDownObject(boardList.find((item) => item.id === value));
            } else {
              if (boardList && boardList.length > 0) {
                setDropDownObject(boardList[0]);
              }
            }
          }
        } else {
          dispatch(fetchBoardRoleType());
        }
      }
    }
  }

  setDropDownOptions(data) {
    const option = [];
    [...data]?.map((item) => {
      const obj = {
        id: item.key,
        value: item.displayName,
      };
      option.push(obj);
    });
    return option;
  }

  render() {
    const { value, setDropDownObject, touched, errors, disabled } = this.props;
    const { list } = this.state;
    return (
      <>
        <DropDownList
          value={value}
          handleChange={(value) => {
            setDropDownObject(value);
          }}
          dropDownData={(list || []).map(({ id, value }) => {
            return {
              id,
              value,
            };
          })}
          touched={touched}
          errors={errors}
          fieldName="Board Role Type"
          positionTop={true}
          disabled={disabled}
        />
        {touched && <FormHelperText>{errors}</FormHelperText>}
      </>
    );
  }
}
const mapStateToProps = ({ boardRoleType }) => {
  return { boardRoleType };
};

export default connect(mapStateToProps)(BoardRoleTypeDropDown);
