import http from "../axios/AxiosInterceptor";
export const GET_CONTRIBUTION_SUCCESS = "GET_CONTRIBUTION_SUCCESS";
export const GET_CONTRIBUTION_RESET = "GET_CONTRIBUTION_RESET";

export const requestGetContribution = (
  pageSize,
  pageNumber,
  orderBy,
  direction,
  contributionYear,
) => {
  const resp = {};
  resp["isFetching"] = true;
  resp["isError"] = false;
  resp["pageSize"] = pageSize;
  resp["pageNumber"] = pageNumber;
  resp["orderBy"] = orderBy;
  resp["direction"] = direction;
  resp["contributionYear"]=contributionYear;
  return {
    type: GET_CONTRIBUTION_SUCCESS,
    data: resp,
  };
};

export const receiveGetContribution = (
  pageSize,
  pageNumber,
  orderBy,
  direction,
  contributionYear,
  data
) => {
  const resp = {};
  resp["isFetching"] = false;
  resp["isError"] = false;
  resp["pageSize"] = pageSize;
  resp["data"] = data;
  resp["pageNumber"] = pageNumber;
  resp["orderBy"] = orderBy;
  resp["direction"] = direction;
  resp["contributionYear"]=contributionYear;
  return {
    type: GET_CONTRIBUTION_SUCCESS,
    data:resp,
  };
};

export const failureGetContribution = (
  pageSize,
  pageNumber,
  orderBy,
  direction,
  contributionYear,
) => {
  const resp = {};
  resp["isFetching"] = false;
  resp["isError"] = true;
  resp["pageSize"] = pageSize;
  resp["pageNumber"] = pageNumber;
  resp["orderBy"] = orderBy;
  resp["direction"] = direction;
  resp["contributionYear"]=contributionYear;
  return {
    type: GET_CONTRIBUTION_SUCCESS,
    data:resp,
  };
};

export const resetGetContribution = () => {
  return {
    type: GET_CONTRIBUTION_RESET,
  };
};

export const fetchContributionFunction = (request) => {
  return (dispatch) => {
    dispatch(
      requestGetContribution(
        request.pageSize,
        request.pageNumber,
        request.orderBy,
        request.direction,
        request.contributionYear,
      )
    );
    const url =
      process.env.REACT_APP_API_DOMAIN +
      `/Contribution/page/${request.pageNumber}/pagesize/${request.pageSize}?orderBy=${request.orderBy}&direction=${request.direction}&contributionYear=${request.contributionYear}&api-version=${process.env.REACT_APP_API_VERSION}`;
    http
      .get(url)
      .then((response) => {
        const data = response.data;
        if (response.status !== 200) {
          dispatch(
            failureGetContribution(
              request.pageSize,
              request.pageNumber,
              request.orderBy,
              request.direction,
              request.contributionYear,
            )
          );
        } else {
          dispatch(
            receiveGetContribution(
              request.pageSize,
              request.pageNumber,
              request.orderBy,
              request.direction,
              request.contributionYear,
              data
            )
          );
        }
      })
      .catch((err) => {
        dispatch(
          failureGetContribution(
            request.pageSize,
            request.pageNumber,
            request.orderBy,
            request.direction,
            request.contributionYear,
          )
        );
      });
  };
};
