import * as React from "react";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import {
  Box,
  Typography,
  Avatar,
  Grow,
  Paper,
  Popper,
  MenuItem,
  MenuList,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import LogoutIcon from "@mui/icons-material/Logout";
import { withRouter } from "react-router-dom";
import { useMsal } from "@azure/msal-react";

const useStyles = makeStyles({
  userControls: {
    "& > .MuiAvatar-root": {
      cursor: "pointer",
    },

    "& .userDropdown": {
      minWidth: 230,
      boxShadow: "0 2px 8px rgb(0 0 0 / 15%)",
      borderRadius: ".625rem",
    },

    "& .MuiMenuItem-root": {
      padding: "15px 20px",
      fontSize: 14,

      "&:hover": {
        background: "none",
        color: "#3e82f7",
      },

      "& .MuiTouchRipple-root": {
        display: "none",
      },

      "& .MuiSvgIcon-root": {
        width: 20,
        height: 20,
        marginRight: 10,
      },
    },

    "& .nav-header": {
      borderBottom: "1px solid #eee",

      "& .MuiTypography-h4": {
        fontSize: "1.05rem",
        color: "#1a3353",
        fontWeight: 700,
        textAlign: "left",
      },

      "& .MuiTypography-body1.role": {
        color: "rgba(114,132,154,.7)!important",
        fontSize: "14px",
      },
    },
  },
});

const UserNav = (props) => {
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);
  const handleToggle = () => {
    setOpen(!open);
  };
  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  const { instance, accounts } = useMsal();
  
  const handleLogout = (instance) => {
    sessionStorage.clear()
    instance.logoutPopup().catch((e) => {
      console.error(e);
    });
  };

  const classes = useStyles();
  const name = accounts[0] && accounts[0].name;
  return (
    <Box className={classes.userControls}>
      <Avatar
        ref={anchorRef}
        onClick={handleToggle}
        alt={name}
        src="https://wdcboardmember-dev.azurewebsites.net/img/avatars/thumb-1.jpg"
      />

      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        placement="bottom-start"
        transition
        disablePortal
        className="userDropdown"
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === "bottom-start" ? "left top" : "left bottom",
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList
                  autoFocusItem={open}
                  id="composition-menu"
                  aria-labelledby="composition-button"
                >
                  <MenuItem onClick={handleClose} className="nav-header">
                    <Box display="flex">
                      <Avatar
                        alt={name}
                        src="https://wdcboardmember-dev.azurewebsites.net/img/avatars/thumb-1.jpg"
                      />
                      <Box pl={2}>
                        <Typography component="h4" variant="h4">
                          {name}
                        </Typography>
                        {/* <Typography
                          component="p"
                          variant="body1"
                          className="role"
                        >
                          Contributor
                        </Typography> */}
                      </Box>
                    </Box>
                  </MenuItem>
                  <MenuItem onClick={()=>handleLogout(instance)}>
                    <LogoutIcon /> Logout
                  </MenuItem>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </Box>
  );
};

export default withRouter(UserNav);
