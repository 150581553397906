export const SIGIN_REQ = "SIGNIN_REQ"
export const SIGIN_SUCCESS = "SIGIN_SUCCESS"
export const SIGIN_FAILURE = "SIGIN_FAILURE"
export const SIGNOUT = "SIGNOUT"


export const signInRequest = () => {
    return {
        type: SIGIN_REQ
    }
}

export const signInSuccess = (data) => {
    return {
        type: SIGIN_SUCCESS,
        data
    }
}

export const signInFailure = () => {
    return {
        type: SIGIN_FAILURE
    }
}

export const signOut = () => {
    return {
        type: SIGNOUT
    }
}

export const fetchSignIn =() => {
    return dispatch =>{
        const access_token = "accessGranted"
        sessionStorage.setItem("access_token", access_token)
        dispatch(signInSuccess(access_token))
    }
}

export const signingOut =() => {
    return dispatch =>{
        sessionStorage.clear()
        dispatch(signOut())
    }
}