import React, { Component } from "react";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import Box from "@mui/material/Box";
import Checkbox from "@mui/material/Checkbox";

import { DropDownList } from "../../common/dropDown";

class AttendanceRow extends Component {
  state = {
    data: {
      boardMemberId: null,
      eventRoleType: null,
      isPresent: false,
    },
    eventList: [],
  };

  componentDidMount() {
    this.setInitialState(this.props);
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    this.setInitialState(newProps);
  }

  setInitialState(props) {
    const { row, attendanceData, eventList } = props;
    if (JSON.stringify(eventList) !== JSON.stringify(this.state.eventList)) {
      this.setState({ eventList });
    }
    if (row) {
      this.setState({
        data: {
          boardMemberId: row.id,
          eventRoleType:
            attendanceData && attendanceData.eventRoleType
              ? attendanceData.eventRoleType
              : null,
          isPresent:
            attendanceData && attendanceData.isPresent
              ? attendanceData.isPresent
              : false,
        },
      });
    }
  }

  setEventRoleType(value) {
    const { data } = this.state;
    const { getInnerValues } = this.props;
    const obj = {
      boardMemberId: data.boardMemberId,
      eventRoleType: value,
      isPresent: data.isPresent,
    };
    this.setState({
      data: obj,
    });
    getInnerValues && getInnerValues(obj);
  }

  handleChange = (event) => {
    const { data } = this.state;
    const { getInnerValues } = this.props;
    const obj = {
      boardMemberId: data.boardMemberId,
      eventRoleType: data.eventRoleType,
      isPresent: event.target.checked,
    };
    this.setState({
      data: obj,
    });
    getInnerValues && getInnerValues(obj);
  };

  render() {
    const { row } = this.props;
    const { data, eventList } = this.state;
    return (
      <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
        <TableCell component="th" scope="row">
          {row.lastName + ", " + row.firstName}
        </TableCell>
        <TableCell>
          <Box maxWidth={150}>
            <DropDownList
              value={data.eventRoleType}
              handleChange={(value) => {
                this.setEventRoleType(value?.id);
              }}
              dropDownData={(eventList || []).map(({ key, displayName }) => {
                return {
                  id: key,
                  value: displayName,
                };
              })}
              // fieldName={isLabelVisible ? "Event Role Type" : ""}
              positionTop={true}
            />
          </Box>
        </TableCell>
        <TableCell align="right">
          <Checkbox
            checked={data && data.isPresent ? true : false}
            onChange={this.handleChange}
          />
        </TableCell>
      </TableRow>
    );
  }
}

export default AttendanceRow;
