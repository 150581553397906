import React, { useState, useRef, useEffect } from "react";
import { useMsal } from "@azure/msal-react";
import { useIsAuthenticated } from "@azure/msal-react";
import { setIdleTimeout } from "./scheduler-service";
import SessionTimeoutDialog from "./SessionDialog";

const sessionTimeout = +process.env.REACT_APP_SESSION_TIMEOUT;
const countDownTime = +process.env.REACT_APP_COUNT_DOWN_TIME;

export const SessionExpireDialogContainer = (accessToken) => {
  const timeRef = useRef(-1);
  const isAuthenticated = useIsAuthenticated();
  const [dialogOpen, setDialogOpen] = useState(false);
  const [remainingTime, setRemainingTime] = useState(countDownTime / 1000);
  const [cancelActivityWatcher, setCancelActivityWatcher] = useState(
    () => () => {}
  );
  const { instance } = useMsal();

  const signOut = () => {
    clearInterval(timeRef.current);
    timeRef.current = -1;
    setDialogOpen(false);
    sessionStorage.clear();
    instance.logoutPopup().catch((e) => {
      console.error(e);
    });
  };

  const startTimer = () => {
    if (timeRef.current === -1) {
      cancelActivityWatcher();
      const endTime = new Date().getTime() + countDownTime;
      setRemainingTime(countDownTime / 1000);
      timeRef.current = setInterval(() => {
        const currentTime = new Date().getTime();
        const totalSecondsRemaining = Math.round(
          (endTime - currentTime) / 1000
        );
        setRemainingTime(totalSecondsRemaining);
        if (totalSecondsRemaining <= 0) {
          signOut();
        }
      }, 1000);
    }
  };

  const showDialog = () => {
    setDialogOpen(true);
    startTimer();
  };
  const setSessionTimeout = () => {
    setCancelActivityWatcher(() =>
      setIdleTimeout(+sessionTimeout, () => {
        if (isAuthenticated && accessToken) {
          showDialog();
        }
      })
    );
  };

  useEffect(() => {
    setSessionTimeout();
    return () => {
      clearInterval(timeRef.current);
      cancelActivityWatcher();
    };
  }, [isAuthenticated, accessToken]);

  const refreshSession = () => {
    clearInterval(timeRef.current);
    timeRef.current = -1;
    setDialogOpen(false);
    cancelActivityWatcher();
    setSessionTimeout();
  };

  return (
    <SessionTimeoutDialog
      remainingTime={remainingTime}
      dialogOpen={dialogOpen}
      onRefreshSession={refreshSession}
      onSignOut={signOut}
      content={{
        title: "Are you still there?",
        content:
          "You've been inactive for a while. For your security, we we'll automatically sign you out in approximately",
        signOutButton: "Sign Out",
        staySignedInButton: "Stay Signed In",
        secondsLeftText: "seconds.",
        minutesText: "minutes",
      }}
    />
  );
};
