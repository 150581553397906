import React from "react";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../../auth/authConfig";
import Button from "@mui/material/Button";

const Login = (props) => {
  const { instance } = useMsal();
 
  // useEffect(()=>{
  //   if(!isAuthenticated) {
  //     instance.loginRedirect(loginRequest).catch(e => {
  //       console.error(e);
  //   });
  //   }
  // },[isAuthenticated])
  const handleLogin = (instance) => {
    instance.loginPopup(loginRequest).catch((e) => {
      console.error(e);
    });
  //   instance.loginRedirect(loginRequest).catch(e => {
  //     console.error(e);
  // });
  };

  return (
    <Button
      variant="contained"
      color="primary"
      onClick={() => handleLogin(instance)}
    >
      Sign in
    </Button>
  );
};

export default Login;
