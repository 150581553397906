export const CONTRIBUTION_SAVED = "Contribution saved successfully";
export const CONTRIBUTION_UPDATED = "Contribution updated successfully";
export const CONTRIBUTION_DELETED = "Contribution deleted successfully";

export const EVENT_SAVED = "Event saved successfully";
export const EVENT_UPDATED = "Event updated successfully";
export const EVENT_DELETED = "Event deleted successfully";

export const BOARD_MEMBER_SAVED = "BoardMember saved successfully";
export const BOARD_MEMBER_UPDATED = "BoardMember updated successfully";
export const BOARD_MEMBER_DELETED = "BoardMember deleted successfully";

export const EVENT_ATTENDANCE = "Attendance updated successfully";

export const ERROR_MESSAGE =
  "There is some issues in services, please try after some time.";
