import {
  CONTRIBUTION_TYPE_REQUEST,
  CONTRIBUTION_TYPE_SUCCESS,
  CONTRIBUTION_TYPE_FAILURE,
  CONTRIBUTION_TYPE_RESET,
} from "../action/ContributionTypeAction";

const initState = {
  loading: false,
  isError: false,
  data: "",
};

const contributionType = (state = initState, action) => {
  switch (action.type) {
    case CONTRIBUTION_TYPE_REQUEST:
      return {
        ...state,
        isFetching: true,
        isError: false,
        data: "",
      };
    case CONTRIBUTION_TYPE_SUCCESS:
      return {
        ...state,
        isFetching: false,
        isError: false,
        data: action.data,
      };
    case CONTRIBUTION_TYPE_FAILURE:
      return {
        ...state,
        isFetching: false,
        isError: true,
      };
    case CONTRIBUTION_TYPE_RESET:
      return {
        ...state,
        isFetching: false,
        data: [],
        isError: false,
      };

    default:
      return state;
  }
};

export default contributionType;
