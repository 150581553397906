import React from "react";
import { Redirect, Route } from "react-router-dom";
import { ROUTES_TEXT } from "../../constants/routes";
import { useIsAuthenticated } from "@azure/msal-react";

const ProtectedRoute = ({ component: Component, render,  ...rest }) => {
  // const isAuthenticated = true;
   const isAuthenticated = useIsAuthenticated();
  return (
    <Route
      {...rest}
      render={(props) => {
        if (!isAuthenticated) {
          return <Redirect to={ROUTES_TEXT.LOGIN} />;
        } 
        return Component ? <Component {...props} /> : render(props);
      }}
    />
  );
};

export default ProtectedRoute;
