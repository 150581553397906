export const ROUTES_TEXT = {
  NOPATH: "/",
  LOGIN: "/login",
  DASHBOARD: "/dashboard",
  CONTRIBUTIONS: "/contributions",
  EVENTS: "/events",
  BOARDMEMBER: "/boardMembers",
  ATTENDANCE: "/attendance/:eventId",
  BOARDMEMBERDETAIL: "/boardMembers/:boardMemberId",
};
